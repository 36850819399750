/**
 * Doubles the amount of coordinates for the array, mainly for smoothening the path animation
 * @param {Array} coordinates Array of coordinates
 */
export function coordinateMapper(coordinates = []) {

    let newCoordinates = [];

    coordinates.map((coordinate, index) => {
        if (coordinates[index + 1]) {
        let newLat = (coordinate.lat + coordinates[index + 1].lat)/2;
        let newLng = (coordinate.lng + coordinates[index + 1].lng)/2;
        let newTime = (coordinate.logTime + coordinates[index + 1].logTime)/2;

        newCoordinates.push(coordinate);
        newCoordinates.push({lat: newLat, lng: newLng, logTime: newTime});
        }
        else {
            newCoordinates.push(coordinate);
        }
    });
    
    return newCoordinates;
}