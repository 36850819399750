import React, { Component } from "react";
import {
    closeSidebar,
    openSidebar,
} from "../../../services/redux/actions/style";
import { connect } from "react-redux";

const showSecondarySidebar = show => WrappedComponent =>
    connect(() => ({}))(
        class extends Component {
            componentDidMount() {
                if (show) {
                    this.props.dispatch(openSidebar());
                } else {
                    this.props.dispatch(closeSidebar());
                }
            }

            render() {
                return <WrappedComponent {...this.props} />;
            }
        }
    );

export default showSecondarySidebar;
