/**
 * Finds the center inside the array of coordinates
 * @param {Array} coordinates Coordinate array
 */
export function findCenterCoordinates(coordinates = []) {
    let latArray = [];
    let lngArray = [];

    coordinates.map(coordinate => {
        latArray.push(coordinate.lat);
        lngArray.push(coordinate.lng);
    });

    let south = Math.min(...latArray);
    let north = Math.max(...latArray);
    let west = Math.min(...lngArray);
    let east = Math.max(...lngArray);


    return ({lat: (north + south)/2, lng: (east + west)/2});
}
