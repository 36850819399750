import React from "react";
import "../App.css";
import { connect } from "react-redux";
import { appStore } from "../services/redux/reducers";
import { Form, Icon, Input, Button, message } from "antd";
import {
    moveToDash,
    moveToMenuSelection,
} from "../navigation/navigationService";
import { signInAccount } from "../services/auth/authService";
import { IMAGERESOURCE, COLOR } from "../constants";
import { user_login, user_logout } from "../services/rest/api";
import Cookies from "js-cookie";
import { push, goBack } from "connected-react-router";

class LoginForm extends React.Component {
    state = {
        email: "",
        password: "",
    };

    render() {
        return (
            <Form className="login-form">
                <Form.Item style={{ marginBottom: "15px" }}>
                    <Input
                        prefix={
                            <Icon
                                type="user"
                                style={{ color: "rgba(0,0,0,.25)" }}
                            />
                        }
                        placeholder="Email"
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                    />
                </Form.Item>
                <Form.Item style={{ marginBottom: "15px" }}>
                    <Input
                        prefix={
                            <Icon
                                type="lock"
                                style={{ color: "rgba(0,0,0,.25)" }}
                            />
                        }
                        type="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={e =>
                            this.setState({ password: e.target.value })
                        }
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        style={{
                            width: "100%",
                            backgroundColor: COLOR.BRAND_PRIMARY,
                            border: "none",
                            boxShadow: "2px 2px 5px #696969",
                        }}
                        type="primary"
                        className="login-form-button"
                        onClick={() =>
                            this.props.onLoginPress(
                                this.state.email,
                                this.state.password
                            )
                        }
                    >
                        LOG IN
                    </Button>
                </Form.Item>
                <Form.Item>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            <a
                                onClick={e => {
                                    this.props.dispatch(
                                        moveToMenuSelection("SignUp")
                                    );
                                }}
                                style={{ color: "black" }}
                            >
                                Interested?
                            </a>
                        </div>
                        <div>
                            <a style={{ color: "black" }} href="">
                                Forgot Password?
                            </a>
                        </div>
                    </div>
                </Form.Item>
            </Form>
        );
    }
}

class LoginPage extends React.Component {
    loginPressed = (email, password) => {
        user_login(email, password)
            .then(res => {
                return res.json();
            })
            .then(this.loginPerformed);
        // signInAccount(email, password, loginResult => {
        //     this.loginPerformed(loginResult);
        //     // user_login(email, password)
        //     //     .then(res => { return res.json() })
        //     //     .then(this.loginPerformed)
        // }
        // );
    };

    loginPerformed = result => {
        // console.log("login result", result.non_field_errors[0]);
        if (result.token) {
            Cookies.set("token", result.token);
            this.props.dispatch(moveToDash());
        } else if (result.details) {
            message.error(result.details);
        } else if (result.non_field_errors[0]) {
            message.error(
                "Unable to log in with provided credentials. Please try again."
            );
        }
        // if (result.type === "success") {
        //     console.log("Trying to navigate to dashboard");
        //     this.props.dispatch(moveToDash());
        // } else {
        //     let errorMessage;
        //     switch (result.error.code) {
        //         case "auth/invalid-email":
        //             errorMessage = "Please enter a valid email address";
        //             break;
        //         case "auth/user-disabled":
        //             errorMessage =
        //                 "User has been disabled, please contact admin";
        //             break;
        //         case "auth/user-not-found":
        //             errorMessage = "User does not exist";
        //             break;
        //         case "auth/wrong-password":
        //             errorMessage = "Entered a wrong password, please try again";
        //             break;
        //         default:
        //             errorMessage = result.error.code;
        //             break;
        //     }
        //     message.error(errorMessage);
        // }
    };

    render() {
        return (
            <div
                style={{
                    height: "100vh",
                    width: "100%",
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        height: "100vh",
                        width: "100%",
                        backgroundImage: `url(${IMAGERESOURCE.LOGINBACKGROUND})`,
                        backgroundSize: "cover",
                    }}
                />

                <div
                    style={{
                        zIndex: 1,
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "white",
                                padding: "35px 30px 15px 30px",
                                borderRadius: "17px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    src={IMAGERESOURCE.LOGO}
                                    alt="TGT"
                                    style={{ height: "150px" }}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}
                            >
                                <div
                                    style={{
                                        width: "250px",
                                        paddingTop: "30px",
                                    }}
                                >
                                    <LoginForm
                                        {...this.props}
                                        onLoginPress={(email, password) =>
                                            this.loginPressed(email, password)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(appStore => appStore)(LoginPage);
