import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    GoogleMap,
    withScriptjs,
    withGoogleMap,
    TrafficLayer,
} from "react-google-maps";
import { DEFAULT_ZOOM_MULTIPLIER, DEFAULT_MAP_CENTER } from "./MapConstants";

/**
 * Generates and setups Google Map for GoogleMaps component
 */

const Maps = withScriptjs(
    withGoogleMap(props => {
        return (
            <GoogleMap
                defaultZoom={DEFAULT_ZOOM_MULTIPLIER}
                defaultCenter={DEFAULT_MAP_CENTER}
                zoom={props.zoom}
                center={props.center}
                ref={props.onMapLoad}
            >
                {props.children}
                {props.showTraffic && <TrafficLayer autoUpdate />}
            </GoogleMap>
        );
    })
);

/**
 * A wrapper for the library react-google-maps
 * @version 1.0.0
 * @see Check https://www.npmjs.com/package/react-google-maps for library npm page
 * @see Check https://tomchentw.github.io/react-google-maps/ for library documentation
 */
export class GoogleMapsComponent extends Component {
    static propTypes = {
        /** Refer to https://tomchentw.github.io/react-google-maps/#installation */
        loadingElement: PropTypes.element.isRequired,
        /** Refer to https://tomchentw.github.io/react-google-maps/#installation */
        containerElement: PropTypes.element.isRequired,
        /** Refer to https://tomchentw.github.io/react-google-maps/#installation */
        mapElement: PropTypes.element.isRequired,
        /** Refer to https://tomchentw.github.io/react-google-maps/#installation */
        googleMapURL: PropTypes.string.isRequired,
    };

    static defaultProps = {
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100vh` }} />,
        mapElement: <div style={{ height: `100%` }} />,
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?v=3.35&key=AIzaSyAyesbQMyKVVbBgKVi2g6VX7mop2z96jBo&libraries=geometry,drawing,places",
    };

    render() {
        return <Maps {...this.props} ref="maps" />;
    }
}
