import React, { Component } from "react";
import { MapContainer } from "../components/MapContainer";
import { connect } from "react-redux";
import showSecondarySidebar from "../components/Layout/helpers/showSecondarySidebar";

class DashboardMain extends Component {
    render() {
        return <MapContainer {...this.props} />;
    }
}

const mapStateToProps = (state, props) => ({});

const ConnectedDashboardMain = connect(mapStateToProps)(DashboardMain);

export default showSecondarySidebar(true)(ConnectedDashboardMain);
