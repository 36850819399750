import config from "../config";
import { includes, pickBy } from "lodash";
import * as ALERT from "./alert";
import * as COLOR from "./color";
import * as IMAGERESOURCE from "./img";

export { ALERT, COLOR, IMAGERESOURCE };

//Resources
export const API_RESOURCES = {
  GET_DATA: "getdata",
  SET_POLYGON: "setpolygon",
  GET_POLYGON: "getpolygon",
  POINT_IN_POLYGON: "pointinpolygon",
  PUT_POI: "putPOI",
  GET_POI: "getPOI",
  IN_RANGE_POI: "inrangePOI",
  SIGN_UP: "signup",
  GET_USER_INFO: "getUserInfo",
  GET_USER_DETAILS: "getUserDetails",
  GET_ALERT: "getAlert",
  UPDATE_USER_INFO: "updateUserInfo",
  QUERY_HIERARCHY: "queryHierarchy",
  REGISTER_DEVICE: "registerDevice",
  UPDATE_DEVICE_INFO: "updateDeviceInfo",
  UPDATE_USER_INFO: "updateUserInfo",
  QUERY_TICKET: "queryTicket",
  GENERATE_TICKET: "generateTicket",
  UPDATE_TICKET: "updateTicket",
  TRANSFER_DEVICE: "tranferDevice",
  DELETE_POI_GEOFENCE: "deletePoiGeofence"
};

export const TICKET_STATUS = {
  OPEN: "OPEN",
  PROCESSING: "PROCESSING",
  RESOLVED: "RESOLVED",
  CANCELED: "CANCELED",
  UNKNOWN: "UNKNOWN"
};

export const DATABASE_TABLE = {
  STATUS_RECORD: config.dbPath.STATUS_RECORD,
  FLEET_RECORD: config.dbPath.FLEET_RECORD,
  DEVICE: config.dbPath.DEVICE,
  POI: config.dbPath.POI,
  GEOFENCE: config.dbPath.GEOFENCE
};

export const SERVICE_TYPE = [
  { label: "Road Assist - Jump Start", value: "ra-jump-start" },
  { label: "Road Assist - Change Battery", value: "ra-change-battery" },
  { label: "Road Assist - Change Tyre", value: "ra-change-tyre" },
  { label: "Road Assist - Locksmith", value: "ra-locksmith" },
  { label: "Road Assist - Others", value: "ra-others" },
  { label: "Collision Towing", value: "ct" },
  { label: "Breakdown Towing", value: "bt" },
  { label: "Crime Prevention Assist - Robbery", value: "cpa-robbery" },
  { label: "Crime Prevention Assist - Road Bully", value: "cpa-road-bully" },
  { label: "Crime Prevention Assist - Hijack", value: "cpa-hijack" },
  { label: "Crime Prevention Assist - Others", value: "cpa-others" }
];

export const GENERAL_CONSTANTS = {
  UPDATE_RATE: 180 * 1000    // milliseconds
};

export const HTTP_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE"
};

export const VEHICLE_STATUS = {
  ALL: "ALL",
  MOVING: "MOVING",
  IDLE: "IDLE",
  PARKING: "PARKING",
  DISCONNECTED: "DISCONNECTED",
  OFFLINE: "OFFLINE",
  INACTIVE: "INACTIVE",
  STOP: "ENGINE STARTED"
  // TRANSIT: "TRANSIT", //most likely deprecated status
};

export const VEHICLE_STATUS_FILTERABLE = pickBy(
  VEHICLE_STATUS,
  value => !includes(["DISCONNECTED", "OFFLINE", "INACTIVE"], value)
);

export const VEHICLE_COLOR = {
  [VEHICLE_STATUS.ALL]: "#C4C4C4",
  [VEHICLE_STATUS.DISCONNECTED]: "#ff2a2a", //red
  [VEHICLE_STATUS.OFFLINE]: "#333333", //black
  [VEHICLE_STATUS.INACTIVE]: "#ff7f2a", //orange
  [VEHICLE_STATUS.MOVING]: "#52C41A", //old color: #37c837
  [VEHICLE_STATUS.IDLE]: "#FFB517", //old color: #7F2AFF
  [VEHICLE_STATUS.PARKING]: "#0488DB", //old color: #D4FF2A
  [VEHICLE_STATUS.STOP]: "#856B37" //old color: #2A2AFF
};

export const REPORT_TYPE = [
  { label: "Speed", value: "speed" },
  // { label: "Moving Direction", value: "direction" },
  { label: "Battery Charge (Ebike)", value: "charge" },
  { label: "Battery Load (Ebike)", value: "current" },
  { label: "Battery Voltage (Ebike)", value: "voltage" },
  { label: "Battery Voltage (Fuel Bike)", value: "vt33_voltage" }
  //{displayName: "Fuel Level", name: "fuel"},
  //{displayName: "Temperature", name: "temperature"}
];

export const FEATURE = {
  DISTRIBUTOR_DASHBOARD: "Distributor Dashboard",
  CUSTOMER: "Customer",
  VEHICLE_LOCATION: "",
  FLEET_TRACKING: "Fleet Tracking",
  SUMMARY: "Summary",
  REPORT: "Report",
  ALERTS: "Alerts",
  POI: "POI",
  GEOFENCE: "Geofence",
  OPERATOR_ACCOUNT: "Operator Account",
  // DEVICES: "Devices", //not used anywhere ATM
  CALLED_REPORT: "Called Report",
  CASES: "Cases",
  NOTIFICATIONS: "Notifications",
  PROFILE: "Profile",
  PREFERENCES: "Preferences",
  ABOUT: "About",
  DEV: "Dev"
};

export const ROLE_TYPE = {
  BUSINESS_OWNER: "Business Owner",
  BUSINESS_ADMIN: "Business Admin",
  DISTRIBUTOR: "Distributor",
  FLEET_OWNER: "Fleet Owner",
  FLEET_MANAGER: "Fleet Manager",
  OPERATOR: "Operator",
  UNKNOWN: "Unknown"
};

export const CREATE_ROLE_TYPE = [
  { label: ROLE_TYPE.DISTRIBUTOR, value: "distributor" },
  { label: ROLE_TYPE.FLEET_OWNER, value: "fleetOwner" },
  { label: ROLE_TYPE.FLEET_MANAGER, value: "fleetManager" }
];

export const DEVICE_TYPE = [{ label: "Global", value: "global" }];

export const SENSOR_TYPE = [
  { label: "None", value: "none" },
  { label: "Fuel", value: "fuel" },
  { label: "Temperature", value: "temperature" }
];

export const TEST_DATA = {
  DEVICE_ID: "865011030129192",
  START_TIME: 1530995744000,
  END_TIME: 1531995744000,
  POI_SET: [
    { id: "1531730859355" },
    { id: "1531730877575" },
    { id: "1531735580575" },
    { id: "1531798804246" },
    { id: "1531798904973" }
  ],
  SHAPE_SET: [
    { id: "1531731053495" },
    { id: "1531731073209" },
    { id: "1531734754400" }
  ]
};

/** To toggle whether to show certain features */
export const FEATURE_TOGGLE = {
  /** Toggle for CMS tab at the Vehicle Dashboard page */
  CMS: false,
  /** Toggle for Serving Device tab at the Device Management page */
  SERVING_DEVICE: false
};
