import React from 'react';
import '../App.css';
import {connect} from 'react-redux';
import { appStore } from '../services/redux/reducers';
import { Form, Icon, Input, Button, Checkbox, Select, Row, Col, DatePicker, Switch, TimePicker} from 'antd';
import { moveToPage, moveToMenuSelection } from '../navigation/navigationService';

class NormalLoginForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item style={{marginBottom: '15px'}}>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input your name!' }],
          })(
            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Name" />
          )}
        </Form.Item>
        <Form.Item style={{marginBottom: '15px'}}>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
          )}
        </Form.Item>
        <Form.Item style={{marginBottom: '15px'}}>
          {getFieldDecorator('contactNumber', {
            rules: [{ required: true, message: 'Please input your contact number!' }],
          })(
            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Contact Number" />
          )}
        </Form.Item>
        <Form.Item>
          <Button style={{width: '100%', backgroundColor:'#FFC412', border:'none', boxShadow: "2px 2px 5px #696969", }} type="primary" htmlType="submit" className="login-form-button" onClick={this.props.removeForm}>
            REGISTER
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm);

class SignedUpMessage extends React.Component{
    render(){
      return(
        <div>
          <div style={{textAlign:'center', color:'#FFC412', fontWeight: 'bold', fontSize: '20px', paddingBottom: '15px', textShadow: "1px 1px 1px #000000"}}>
            Thank you for registering your interest in our product!
          </div>
          <div style={{textAlign:'center', color:'black', fontSize: '14px'}}>
            Our team will get back to you with more details regarding the product soonest possible.
          </div>

          {
            // <div style={{textAlign:'center', color:'black', fontSize: '14px'}}>
            //   Please check the inbox of the e-mail address provided to verify your account before you attempt to
            //   <a onClick={(e) => {this.props.data.dispatch(moveToMenuSelection("Login"))}} style={{color:'#FFC412', fontWeight: 'bold'}} href=""> Log In</a>
            // </div>
          }
        </div>
      )
    }
}

class SignUpPage extends React.Component{
  state = {
    signUpComplete: false,
  };

  removeForm(){
    this.setState({signUpComplete: true})
  }

  render(){
    return(
      <div style={{height: '100vh', width: '100%', display:"flex", flex:1, alignItems: 'center',}}>

      <div style={{ position:'absolute', height: '100vh', width: '100%', backgroundImage: 'url("http://www.gpsfleet.com.my/wp-content/uploads/2014/05/image5-1.png")', backgroundSize: "cover"}}/>

      <div style={{zIndex: 1, display:'flex', flexDirection:'column', width: '100%', justifyContent:'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'center'}}>
        <div style={{ backgroundColor:'white', padding: '35px 30px 30px 30px', borderRadius: "17px"}}>
          <div style={{display:'flex', flexDirection: 'row', justifyContent:'center'}}>
            <img
                src={this.props.style.logoUrl}
                alt="GPSFleet"
                style={{ height: "80px" }}
            />
          </div>

          <div style={{display:'flex', flexDirection: 'row', justifyContent:'center'}}>
            <div style={{width: '250px', paddingTop:'30px'}}>
              {
                this.state.signUpComplete? <SignedUpMessage data={this.props}/> : <WrappedNormalLoginForm removeForm={()=>this.removeForm()}/>
              }
            </div>
          </div>
          </div>
          </div>
        </div>

      </div>
    )
  }
}

export default connect(appStore)(SignUpPage);
