import * as ActionTypes from "../action-types/request";
import { getData, postData, putData, deleteData } from "../../rest/RestRequest";
import { API_RESOURCES } from "../../../constants";

/**
 * Performs a GET request to server and retrieve data
 * @param {string} resource API resource to request
 * @param {object} params Additional request parameters in object form
 * @param {string} target Allocated name for the request result in "data" state
 */
export const requestData = (
    resource,
    params,
    target,
    callback = () => {}
) => dispatch => {
    dispatch(requestDataStart(target));

    return getData(resource, params)
        .then(response => {
            if (response.status === 200) return response.json();
            else throw new Error(`${response.status} ${response.statusText}`);
        })
        .then(result => {
            // console.log('result :', result);
            if (!result)
                throw new Error(
                    "No data recieved. Invalid request identifier?"
                );
            dispatch(requestDataSuccess(result, target));
            callback(result);
        })
        .catch(errorMessage => dispatch(requestDataFail(errorMessage, target)));
};

const requestDataStart = target => {
    return {
        type: ActionTypes.REQUEST_DATA_START,
        target,
    };
};

const requestDataSuccess = (result, target) => {
    return {
        type: ActionTypes.REQUEST_DATA_SUCCESS,
        result,
        target,
    };
};

const requestDataFail = (errorMessage, target) => {
    return {
        type: ActionTypes.REQUEST_DATA_FAIL,
        errorMessage,
        target,
    };
};

export const requestDataList = (
    resource,
    paramsList = [],
    target,
    callback = () => {}
) => dispatch => {
    dispatch(requestDataListStart(target));

    let resultArray = [];

    let promiseList = paramsList.map(async params => {
        // console.log('params :', params);

        let response = await getData(resource, params);

        // console.log('single response :', response);

        const result = await response.json();

        if (response.status === 200) {
            if (Array.isArray(result))
                result.map(resultObject => resultArray.push(resultObject));
            else resultArray.push(result);
        } else throw new Error(`${response.status} ${response.statusText}`);
    });

    // console.log('promiseList :', promiseList);

    return Promise.all(promiseList)
        .then(() => {
            dispatch(requestDataListSuccess(resultArray, target));
            callback(resultArray);
        })
        .catch(error => dispatch(requestDataListFail(error, target)));
};

const requestDataListStart = target => {
    return {
        type: ActionTypes.REQUEST_DATA_LIST_START,
        target,
    };
};

const requestDataListSuccess = (results, target) => {
    return {
        type: ActionTypes.REQUEST_DATA_LIST_SUCCESS,
        result: results.filter(result => {
            return result
                ? result.hasOwnProperty("archived")
                    ? result.archived || result.archived === "true"
                        ? !result.archived
                        : result
                    : result
                : result;
        }),
        target,
    };
};

const requestDataListFail = (errorMessage, target) => {
    return {
        type: ActionTypes.REQUEST_DATA_LIST_FAIL,
        errorMessage,
        target,
    };
};

/**
 * Performs a POST request to server and create data in server
 * @param {string} resource API resource to request
 * @param {object} requestBody Data to create in server
 * @param {string} target Allocated name for the request result in "data" state
 */
export const createData = (
    resource,
    requestBody,
    target,
    callback = () => {}
) => dispatch => {
    dispatch(createDataStart(target));

    return postData(resource, requestBody)
        .then(response => {
            if (response.status >= 200 && response.status < 300)
                return response.json();
            //Assuming body only in string form
            else throw new Error(`${response.status} ${response.statusText}`);
        })
        .then(result => {
            dispatch(createDataSuccess(result, target));
            callback(result);
        })
        .catch(errorMessage => dispatch(createDataFail(errorMessage, target)));
};

const createDataStart = target => {
    return {
        type: ActionTypes.CREATE_DATA_START,
        target,
    };
};

const createDataSuccess = (result, target) => {
    console.log("Successfully created data", result);
    return {
        type: ActionTypes.CREATE_DATA_SUCCESS,
        result,
        target,
    };
};

const createDataFail = (errorMessage, target) => {
    console.log("Error creating data", errorMessage);
    return {
        type: ActionTypes.CREATE_DATA_FAIL,
        errorMessage,
        target,
    };
};

/** POI CREATION */

export const createPOI = (POIData, callback = () => {}) => dispatch => {
    dispatch(createPOIStart());

    return postData(API_RESOURCES.PUT_POI, POIData)
        .then(response => {
            if (response.status >= 200 && response.status < 300)
                return response.json();
            //Assuming body only in string form
            else throw new Error(`${response.status} ${response.statusText}`);
        })
        .then(createdPOIData => {
            dispatch(createPOISuccess(createdPOIData));
            callback(createdPOIData);
        })
        .catch(errorMessage => dispatch(createPOIFail(errorMessage)));
};

const createPOIStart = () => {
    return {
        type: ActionTypes.CREATE_POI_START,
    };
};

const createPOISuccess = result => {
    console.log("Successfully created data", result);
    return {
        type: ActionTypes.CREATE_POI_SUCCESS,
        result,
    };
};

const createPOIFail = errorMessage => {
    console.log("Error creating data", errorMessage);
    return {
        type: ActionTypes.CREATE_POI_FAIL,
        errorMessage,
    };
};

/** GEOFENCE CREATION */

export const createGeofence = (
    geofenceDataList,
    callback = () => {}
) => dispatch => {
    dispatch(createGeofenceStart());

    let promiseList = geofenceDataList.map(async geofenceData => {
        let response = await postData(API_RESOURCES.SET_POLYGON, geofenceData);
        if (response.status >= 200 && response.status < 300)
            return response.json();
        else throw new Error(`${response.status} ${response.statusText}`);
    });

    return Promise.all(promiseList)
        .then(result => {
            console.log("create list result :", result);
            dispatch(createGeofenceSuccess(result));
            callback(result);
        })
        .catch(error => dispatch(createGeofenceFail(error)));
};

const createGeofenceStart = () => {
    return {
        type: ActionTypes.CREATE_GEOFENCE_START,
    };
};

const createGeofenceSuccess = result => {
    console.log("Successfully created data", result);
    return {
        type: ActionTypes.CREATE_GEOFENCE_SUCCESS,
        result,
    };
};

const createGeofenceFail = errorMessage => {
    console.log("Error creating data", errorMessage);
    return {
        type: ActionTypes.CREATE_GEOFENCE_FAIL,
        errorMessage,
    };
};

/**
 * Performs a list POST request to server and create an array of data in server
 * @param {string} resource API resource to request
 * @param {Array} requestBodyList Array of data to create in server
 * @param {string} target Allocated name for the request result in "data" state
 */
export const createDataList = (
    resource,
    requestBodyList,
    target,
    callback = () => {}
) => dispatch => {
    dispatch(createDataListStart(target));

    let promiseList = requestBodyList.map(async requestBody => {
        let response = await postData(resource, requestBody);
        if (response.status >= 200 && response.status < 300)
            return response.json();
        else throw new Error(`${response.status} ${response.statusText}`);
    });

    return Promise.all(promiseList)
        .then(result => {
            console.log("create list result :", result);
            dispatch(createDataListSuccess(result, target));
            callback(result);
        })
        .catch(error => dispatch(createDataListFail(error, target)));
};

const createDataListStart = target => {
    return {
        type: ActionTypes.CREATE_DATA_LIST_START,
        target,
    };
};

const createDataListSuccess = (result, target) => {
    return {
        type: ActionTypes.CREATE_DATA_LIST_SUCCESS,
        result,
        target,
    };
};

const createDataListFail = (errorMessage, target) => {
    return {
        type: ActionTypes.CREATE_DATA_LIST_FAIL,
        errorMessage,
        target,
    };
};

/**
 * Performs a PUT request to server and updates data in server
 * @param {string} resource API resource to be requested
 * @param {object} params Additional request parameters in object form
 * @param {object} requestBody Data to be updated
 * @param {string} target Allocated name for the resquest result in "data" state
 */
export const updateData = (
    resource,
    params,
    requestBody,
    target,
    callback = () => {}
) => dispatch => {
    dispatch(updateDataStart(target));

    return putData(resource, params, requestBody)
        .then(response => {
            if (response.status >= 200 && response.status < 300)
                return response.json();
            else throw new Error(`${response.status} ${response.statusText}`);
        })
        .then(result => {
            dispatch(updateDataSuccess(result, target));
            callback(result);
        })
        .catch(errorMessage => dispatch(updateDataFail(errorMessage, target)));
};

const updateDataStart = target => {
    return {
        type: ActionTypes.UPDATE_DATA_START,
        target,
    };
};

const updateDataSuccess = (result, target) => {
    return {
        type: ActionTypes.UPDATE_DATA_SUCCESS,
        result,
        target,
    };
};

const updateDataFail = (errorMessage, target) => {
    return {
        type: ActionTypes.UPDATE_DATA_FAIL,
        errorMessage,
        target,
    };
};

/**
 * Performs a DELETE request to server and remove data in server
 * @param {string} resource API resource to request
 * @param {object} params Additional request parameters in object form
 * @param {string} target Allocated name for the request result in "data" state
 */
export const removeData = (
    resource,
    params,
    target,
    callback = () => {}
) => dispatch => {
    dispatch(removeDataStart(target));

    return deleteData(resource, params)
        .then(response => {
            if (response.status >= 200 && response.status < 300)
                return response.json();
            else throw new Error(`${response.status} ${response.statusText}`);
        })
        .then(result => {
            dispatch(removeDataSuccess(result, target));
            callback(result);
        })
        .catch(errorMessage => dispatch(removeDataFail(errorMessage, target)));
};

const removeDataStart = target => {
    return {
        type: ActionTypes.REMOVE_DATA_START,
        target,
    };
};

const removeDataSuccess = (result, target) => {
    return {
        type: ActionTypes.REMOVE_DATA_SUCCESS,
        result,
        target,
    };
};

const removeDataFail = (errorMessage, target) => {
    return {
        type: ActionTypes.REMOVE_DATA_FAIL,
        errorMessage,
        target,
    };
};
