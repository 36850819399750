import React from "react";
import { Switch, Route } from "react-router";
import asyncComponent from "./../components/AsyncComponent";

const AsyncDashBoard = asyncComponent(() => import("../pages/DashBoard"));
const AsyncDistributorAddNewDevicePage = asyncComponent(() =>
  import("../pages/DistributorAddNewDevicePage")
);
const AsyncEditCustomerDetailsPage = asyncComponent(() =>
  import("../pages/EditCustomerDetailsPage")
);
const AsyncAddNewCustomerPage = asyncComponent(() =>
  import("../pages/AddNewCustomerPage")
);
const AsyncPOIPage = asyncComponent(() => import("../pages/POIPage"));
const AsyncAddNewPOIPage = asyncComponent(() =>
  import("../pages/AddNewPOIPage")
);
const AsyncGeofencePage = asyncComponent(() => import("../pages/GeofencePage"));
const AsyncAddNewGeofencePage = asyncComponent(() =>
  import("../pages/AddNewGeofencePage")
);
const AsyncOperatorAccountPage = asyncComponent(() =>
  import("../pages/OperatorAccountPage")
);
const AsyncAddNewOperatorAccountPage = asyncComponent(() =>
  import("../pages/AddNewOperatorAccountPage")
);
const AsyncEditOperatorAccountPage = asyncComponent(() =>
  import("../pages/EditOperatorAccountPage")
);
const AsyncFleetTrackingPage = asyncComponent(() =>
  import("../pages/FleetTrackingPage")
);
const AsyncSummaryPage = asyncComponent(() =>
  import("../pages/SummaryPage")
);
const AsyncCalledReportPage = asyncComponent(() =>
  import("../pages/CalledReportPage")
);
const AsyncCasesPage = asyncComponent(() => import("../pages/CasesPage"));
const AsyncCaseChronologyPage = asyncComponent(() =>
  import("../pages/CaseChronologyPage")
);
const AsyncNotificationsPage = asyncComponent(() =>
  import("../pages/NotificationsPage")
);
const AsyncProfilePage = asyncComponent(() => import("../pages/ProfilePage"));
const AsyncEditPasswordPage = asyncComponent(() =>
  import("../pages/EditPasswordPage")
);
const AsyncEditEmailPage = asyncComponent(() =>
  import("../pages/EditEmailPage")
);
const AsyncEditPhoneNumberPage = asyncComponent(() =>
  import("../pages/EditPhoneNumberPage")
);
const AsyncEditNamePage = asyncComponent(() => import("../pages/EditNamePage"));
const AsyncEditLogoPage = asyncComponent(() => import("../pages/EditLogoPage"));
const AsyncPreferencesPage = asyncComponent(() =>
  import("../pages/PreferencesPage")
);
const AsyncAboutPage = asyncComponent(() => import("../pages/AboutPage"));
const AsyncDevPage = asyncComponent(() => import("../pages/DevPage"));
const AsyncNotFoundPage = asyncComponent(() => import("../pages/NotFoundPage"));

/**
 * Available Routes for the Secondary Sidebar
 */
export const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={AsyncDashBoard} />
      <Route
        exact
        path="/Customer/Edit"
        component={AsyncEditCustomerDetailsPage}
      />
      {/* <Route
        exact
        path="/Customer/AddNew"
        component={AsyncAddNewCustomerPage}
      /> */}
      <Route
        exact
        path="/Customer/AddNewDevice"
        component={AsyncDistributorAddNewDevicePage}
      />
      <Route exact strict path="/POI" component={AsyncPOIPage} />
      <Route exact path="/POI/AddNew" component={AsyncAddNewPOIPage} />
      <Route exact strict path="/Geofence" component={AsyncGeofencePage} />
      <Route
        exact
        path="/Geofence/AddNew"
        component={AsyncAddNewGeofencePage}
      />
      <Route
        exact
        strict
        path="/Operator Account"
        component={AsyncOperatorAccountPage}
      />
      <Route
        exact
        path="/Operator Account/AddNew"
        component={AsyncAddNewOperatorAccountPage}
      />
      <Route
        exact
        path="/Operator Account/Edit"
        component={AsyncEditOperatorAccountPage}
      />
      <Route exact path="/Fleet Tracking" component={AsyncFleetTrackingPage} />
      <Route exact path="/Called Report" component={AsyncCalledReportPage} />
      <Route exact path="/Cases" component={AsyncCasesPage} />
      <Route
        exact
        path="/Cases/Chronology"
        component={AsyncCaseChronologyPage}
      />
      <Route exact path="/Notifications" component={AsyncNotificationsPage} />
      <Route exact path="/Profile" component={AsyncProfilePage} />
      <Route
        exact
        path="/Profile/EditPassword"
        component={AsyncEditPasswordPage}
      />
      <Route exact path="/Profile/EditName" component={AsyncEditNamePage} />
      <Route exact path="/Profile/EditEmail" component={AsyncEditEmailPage} />
      <Route
        exact
        path="/Profile/EditPhoneNumber"
        component={AsyncEditPhoneNumberPage}
      />
      <Route exact path="/Profile/EditLogo" component={AsyncEditLogoPage} />
      <Route exact path="/Preferences" component={AsyncPreferencesPage} />
      <Route exact path="/About" component={AsyncAboutPage} />
      <Route exact path="/Dev" component={AsyncDevPage} />
      <Route component={AsyncNotFoundPage} />
    </Switch>
  );
};
