import React, { Component } from "react";
import PropTypes from "prop-types";
import OwningDeviceList from "./OwningDeviceList";
// import ServingDeviceList from "./ServingDeviceList";
import { requestData } from "../../../services/redux/actions/request";
import { API_RESOURCES } from "../../../constants";
import { connect } from "react-redux";

/**
 * Displays user's owning device and serving device
 */
class DevicePanel extends Component {
    static propTypes = {
        /** Firebase UID of the user */
        firebaseUserId: PropTypes.string.isRequired,
    };

    state = {
        user: {},
    };

    /**
     * Gets user information from the server
     */
    getUserInfo = () => {
        const { dispatch, firebaseUserId } = this.props;
        const requestKey = `user${firebaseUserId}`;
        dispatch(
            requestData(
                API_RESOURCES.GET_USER_INFO,
                { firebaseUserId },
                requestKey,
                result => {
                    this.setState({ user: result });
                }
            )
        );
    };

    componentDidMount() {
        // this.getUserInfo();
    }

    componentDidUpdate(prevProps) {
        // if (this.props.firebaseUserId !== prevProps.firebaseUserId) {
        //   this.getUserInfo();
        // }
    }

    render() {
        // const { TabPane } = Tabs;
        const { match, actionsDisabled } = this.props;

        let { user } = this.state;
        if (!user) user = {};
        console.log("here");

        return (
            <div style={{ display: "flex", flex: 1 }}>
                <OwningDeviceList
                    deviceList={user.viewableDevice}
                    match={match}
                    actionsDisabled={actionsDisabled}
                />
            </div>
        );
    }
}

export default connect(appStore => appStore)(DevicePanel);
