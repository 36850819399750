import Cookies from "js-cookie";
import history from "../history";

const URL = "https://vmd-api.tgtautomotive.com/fms";
// const URL = "http://localhost:8000/fms";
const defaultTimeout = 70000;

let headers = {
    Accept: "*/*",
    "Content-Type": "application/json; charset=utf-8",
};

const fetchWithTimeout = (url, options, timeout = defaultTimeout) => {
    //console.log(url);
    return Promise.race([
        fetch(url, options),
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error("timeout")), timeout)
        ),
    ]);
};

const headers_with_auth = () => {
    const token = Cookies.get("token");
    if (!token) {
        return headers;
    }
    return {
        ...headers,
        Authorization: `Token ${Cookies.get("token")}`,
    };
};

const secureFetch = (url, method, data) => {
    return fetchWithTimeout(URL + url, {
        method,
        headers: headers_with_auth(),
        body: JSON.stringify(data),
    }).then(res => {
        if (res.status === 401 && history.location.pathname !== "/Login") {
            //redirect to login page
            // history.push("/login?redirect");
            // console.log(moveToLogin);
            // moveToLogin();
            history.push("/Login");
            window.location = "/Login";
        }
        return res;
    });
};
const securezFetch = (url, method, data) => {
    return fetchWithTimeout(url, {
        method,
        headers,
        body: JSON.stringify(data),
    });
    // .then(res => {
    //     if (res.status === 401 && history.location.pathname !== "/Login") {
    //         //redirect to login page
    //         // history.push("/login?redirect");
    //         // console.log(moveToLogin);
    //         // moveToLogin();
    //         history.push("/Login");
    //         window.location = '/Login';
    //     }
    //     return res;
    // });
};

const handleErrors = response => {
    // if (!response.ok) {
    //     throw Error(response.statusText);
    // }
    return response;
};

export const user_login = (username, password) => {
    return fetchWithTimeout(`${URL}/auth/login/`, {
        method: "POST",
        headers,
        body: JSON.stringify({ username, password }),
    }).then(handleErrors);
};

export const user_logout = () => {
    return secureFetch("/auth/logout/", "POST").then(handleErrors);
};

export const get_metric_history = (device_id, param, time_start, time_end) => {
    return secureFetch(
        `/data/${device_id}/${param}/?ordering=timestamp` +
            `&timestamp__gte=${time_start}&timestamp__lt=${time_end}`,
        "GET"
    ).then(handleErrors);
};

export const export_metric_data = (device_id, param, time_start, time_end) => {
    return secureFetch(
        `/data/${device_id}/${param}/export/?ordering=timestamp` +
            `&timestamp__gte=${time_start}&timestamp__lt=${time_end}`,
        "GET"
    ).then(handleErrors);
};

export const get_all_metrics = device_id => {
    return secureFetch(`/data/${device_id}/`, "GET");
};

export const get_commands = device_id => {
    return secureFetch(`/command/${device_id}/`, "GET");
};

export const send_command = (device_id, command) => {
    return secureFetch(`/command/${device_id}/`, "POST", {
        command,
    }).then(handleErrors);
};

export const get_status = (device_id, time_start, time_end) => {
    return secureFetch(
        `/status/${device_id}/` +
            `?time_start__lt=${time_end}&time_end__gte=${time_start}&ordering=time_start`,
        "GET"
    ).then(handleErrors);
};

export const get_current_status = device_id => {
    return secureFetch(`/status/${device_id}/`, "GET").then(handleErrors);
};

export const get_location = (device_id, time_start, time_end) => {
    return secureFetch(
        `/data/location/${device_id}/` +
            `?timestamp__gte=${time_start}&timestamp__lt=${time_end}&ordering=timestamp`,
        "GET"
    ).then(handleErrors);
};

export const get_current_location = device_id => {
    return secureFetch(`/data/location/${device_id}/`, "GET").then(
        handleErrors
    );
};

export const get_device_details = device_id => {
    return secureFetch(`/device/details/${device_id}/`, "GET").then(
        handleErrors
    );
};

export const get_device_list = () => {
    return secureFetch(`/device/`, "GET").then(handleErrors);
};

export const get_device_live = () => {
    return secureFetch(`/device/live/`, "GET").then(handleErrors);
};

export const get_summary = (date) => {
    return secureFetch(`/device/summary/?&date=${date}`, "GET").then(handleErrors);
};

export const get_alert = (device_id, time_start, time_end) => {
    return secureFetch(
        `/alert/${device_id}/` +
            `?time_start__lt=${time_end}&time_end__gte=${time_start}&ordering=time_start`,
        "GET"
    ).then(handleErrors);
};

export const get_account = () => {
    return secureFetch(`/account/`, "GET").then(handleErrors);
};
// export const get_poi = () => {
//     return securezFetch("http://10.10.2.100:8099/area/poi/", "GET").then(
//         handleErrors
//     );
// };
export const post_poi = () => {
    return securezFetch("http://10.10.2.100:8099/area/poi/", "POST  ").then(
        handleErrors
    );
};
export const get_poi = () => {
    return secureFetch("/area/poi/", "GET").then(handleErrors);
};
export const get_geofence = () => {
    return secureFetch("/area/geofence/", "GET").then(handleErrors);
};
// export const get_geofence = () => {
//     return securezFetch("http://10.10.2.100:8099/area/geofence/", "GET").then(
//         handleErrors
//     );
// };

// export const get_summary = (device_list, time_start, time_end) => {
//     return secureFetch(`/device/summary/?device_ids=${device_list}` +
//         `&timestamp__gte=${time_start}&timestamp__lt=${time_end}`, "GET"
//     ).then(handleErrors);
// }

/*
export const get_users = filter => {
    return secureFetch(`/users/?search=${filter}`, "GET").then(handleErrors);
};

export const get_presence_by_user_id = uid => {
    return secureFetch(`/proxy/tracker/today/user/${uid}/`, "GET");
};

export const get_profile_by_username = (username, date_start, date_end) => {
    return secureFetch(`/proxy/profile/user/${username}/`, "POST", {
        date_start,
        date_end,
    });
};

export const update_settings = settings => {
    return secureFetch("/settings/company/", "PATCH", settings);
};*/
