import React from "react";
import PropTypes from "prop-types";
import { Menu } from "antd";
import SidebarItem from "./SidebarItem";
import "./SidebarItems.css";

const SidebarItems = props => {
  return (
    <Menu
      theme={props.theme}
      selectedKeys={props.selectedKeys}
      onClick={props.onClick}
      style={{
        height: "calc(100vh - 68px)", // Minus the logo height
        overflowY: "auto",
        background: "transparent"
      }}
      {...props}
    >
      {props.items.map(item => (
        <SidebarItem
          key={item.key}
          icon={item.icon}
          iconType={item.iconType}
          label={item.label}
        />
      ))}
    </Menu>
  );
};

SidebarItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(["light", "dark"]),
  collapsed: PropTypes.bool,
  selectedKeys: PropTypes.string
};

SidebarItems.defaultProps = {
  items: [],
  onClick: () => {},
  theme: "dark",
  collapsed: true
};

export default SidebarItems;
