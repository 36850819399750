import React from "react";
import config from "../config";
import GoogleMaps from "./google-maps/GoogleMaps";
import GoogleMapsDraw from "./google-maps/GoogleMapsDraw";
import { DRAW_MODE, MARKER } from "./google-maps/MapConstants";
import { LoadingIndicator } from "./custom-components/LoadingIndicator";
import {
    passPointCoordinate,
    passPolygonCoordinateList,
} from "../services/redux/actions/mapControl";

export let MAP_INSTANCE;

export const MapContainer = ({ dispatch, style, mapControl }) => (
    <div id="MapContainer" className="map-container">
        {mapControl ? (
            <MapsRenderer
                center={[2, 110]}
                playbackSpeed={0.5}
                markerList={mapControl.markerList}
                shapeList={mapControl.shapeList}
                poiList={mapControl.poiList}
                pathCoordinates={mapControl.pathCoordinates}
                mode={mapControl.mode}
                showTraffic={true}
                onPlaybackComplete={() => console.log("Playback Completed")}
                googleMapURL={config.googleApiURL}
                drawType={mapControl.drawType}
                drawModeMarkerPosition={mapControl.poiCoordinate}
                onDrawComplete={result => {
                    mapControl.drawType === MARKER
                        ? dispatch(passPointCoordinate(result))
                        : dispatch(passPolygonCoordinateList(result));
                }}
                dispatch={dispatch}
            />
        ) : (
            <LoadingIndicator />
        )}

        <button
            id="openNav"
            className="map-drawer-button"
            onClick={() => this.openNav()}
        >
            <img
                src="https://cdnjs.cloudflare.com/ajax/libs/ionicons/4.4.1/collection/icon/svg/ios-more.svg"
                alt="icon"
            />
        </button>
    </div>
);

const MapsRenderer = class extends React.Component {
    render() {
        const { props } = this;

        const { mode } = props;
        let mapType = "locate";

        if (mode === DRAW_MODE) mapType = "draw";

        return mapType === "draw" ? (
            <GoogleMapsDraw {...props} />
        ) : (
            <GoogleMaps
                {...props}
                ref={instance => {
                    MAP_INSTANCE = instance;
                }}
            />
        );
    }
};
