export const REQUEST_DATA_START = "REQUEST_DATA_START";
export const REQUEST_DATA_SUCCESS = "REQUEST_DATA_SUCCESS";
export const REQUEST_DATA_FAIL = "REQUEST_DATA_FAIL";

export const REQUEST_DATA_LIST_START = "REQUEST_DATA_LIST_START";
export const REQUEST_DATA_LIST_SUCCESS = "REQUEST_DATA_LIST_SUCCESS";
export const REQUEST_DATA_LIST_FAIL = "REQUEST_DATA_LIST_FAIL";

export const CREATE_DATA_START = "CREATE_DATA_START";
export const CREATE_DATA_SUCCESS = "CREATE_DATA_SUCCESS";
export const CREATE_DATA_FAIL = "CREATE_DATA_FAIL";

export const CREATE_DATA_LIST_START = "CREATE_DATA_LIST_START";
export const CREATE_DATA_LIST_SUCCESS = "CREATE_DATA_LIST_SUCCESS";
export const CREATE_DATA_LIST_FAIL = "CREATE_DATA_LIST_FAIL";

export const UPDATE_DATA_START = "UPDATE_DATA_START";
export const UPDATE_DATA_SUCCESS = "UPDATE_DATA_SUCCESS";
export const UPDATE_DATA_FAIL = "UPDATE_DATA_FAIL";

export const REMOVE_DATA_START = "REMOVE_DATA_START";
export const REMOVE_DATA_SUCCESS = "REMOVE_DATA_SUCCESS";
export const REMOVE_DATA_FAIL = "REMOVE_DATA_FAIL";

/** POI CREATION */
export const CREATE_POI_START = "CREATE_POI_START";
export const CREATE_POI_SUCCESS = "CREATE_POI_SUCCESS";
export const CREATE_POI_FAIL = "CREATE_POI_FAIL";

/** GEOFENCE CREATION */
export const CREATE_GEOFENCE_START = "CREATE_GEOFENCE_START";
export const CREATE_GEOFENCE_SUCCESS = "CREATE_GEOFENCE_SUCCESS";
export const CREATE_GEOFENCE_FAIL = "CREATE_GEOFENCE_FAIL";
