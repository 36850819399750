import React from "react";
import PropTypes from "prop-types";
import "./Logo.css";

import {IMAGERESOURCE} from "../../../constants"

const Logo = ({ onClick, selectedKeys }) => {
  return (
    <div className="sidebar-logo-container" key="/" onClick={onClick} style={{
        background: selectedKeys[0] === "/" ? "rgba(0, 0, 0, 0.25)" : ""
    }}>
      <img
        src={IMAGERESOURCE.SIDEBARLOGO}
        className="sidebar-logo"
        alt = "Sidebar Logo"
      />
    </div>
  );
};

Logo.propTypes = {
  onClick: PropTypes.func
};

export default Logo;
