import React, { Component } from "react";
import PropTypes from "prop-types";
import { moveToPage } from "../../../navigation/navigationService";
import { message, Popconfirm, Button, Icon, Table } from "antd";
import { connect } from "react-redux";
import { EmptyIndicator } from "../EmptyIndicator";
import { API_RESOURCES, DATABASE_TABLE } from "../../../constants";
import {
    createData,
    requestData,
    requestDataList,
} from "../../../services/redux/actions/request";
import moment from "moment";
import { saveUserInfo } from "../../../services/redux/actions/user";
import { get_device_list } from "../../../services/rest/api";
import { getCurrentUserRole } from "../../../services/hierarchy/hierarchy";
import { PrimaryButton } from "../../Button";

class OwningDeviceList extends Component {
    static propTypes = {
        /** List of device ID to show */
        deviceList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    };

    static defaultProps = {
        deviceList: [],
    };

    state = {
        devices: [],
    };

    componentDidMount() {
        this.props.dispatch(() => {
            get_device_list()
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    let viewableDevice = res.reduce((acc, cur, idx) => {
                        acc.push({
                            deviceId: cur.device_id,
                            plateNumber: cur.license_plate,
                            expiryDate: cur.sim_expiry,
                            simCardNumber: cur.phone_number,
                        });
                        return acc;
                    }, []);
                    this.setState({ devices: viewableDevice });
                });
        });
        // this.getDevicesInfo(this.props.deviceList);
    }

    componentDidUpdate(prevProps) {
        // this.props.dispatch(
        //   () => {
        //     get_device_list()
        //       .then(res => { return res.json() })
        //       .then(
        //         res => {
        //           let viewableDevice = res.reduce(
        //             (acc, cur, idx) => {
        //               acc.push({
        //                 deviceId: cur.device_id,
        //                 plateNumber: cur.license_plate,
        //                 expiryDate: cur.sim_expiry,
        //                 simCardNumber: cur.phone_number
        //               });
        //               return acc;
        //             },
        //             []
        //           );
        //           this.setState({ devices: viewableDevice });
        //         }
        //       )
        //   }
        // );
        // if (this.props.deviceList != prevProps.deviceList) {
        //   this.getDevicesInfo(this.props.deviceList);
        // }
    }

    getDevicesInfo = deviceIds => {
        this.props.dispatch(
            requestDataList(
                API_RESOURCES.GET_DATA,
                deviceIds.map(deviceId => ({
                    tableName: DATABASE_TABLE.DEVICE,
                    id: deviceId,
                    data: [
                        "deviceId",
                        "plateNumber",
                        "expiryDate",
                        "simCardNumber",
                    ],
                })),
                "devices",
                result => {
                    this.setState({ devices: result });
                }
            )
        );
    };

    renewDevice = deviceId => {
        const loadingIndicator = message.loading("Renewing device...");
        const { request, dispatch } = this.props;
        const requestKey = `device${deviceId}`;
        const { result } = request.data[requestKey];
        let { expiryDate } = result[0];
        const newExpiryDate = expiryDate + 1 * 365 * 24 * 60 * 60 * 1000;

        dispatch(
            createData(
                API_RESOURCES.UPDATE_DEVICE_INFO,
                {
                    deviceId: deviceId,
                    updateRequest: {
                        updateMethod: "SET",
                        relatedAttributes: "expiryDate",
                        relatedData: newExpiryDate,
                    },
                },
                "renewDevice",
                () => {
                    loadingIndicator();
                    message.success("Device removed");

                    let devices = this.state.devices.map(device => {
                        if (device.deviceId === result[0].deviceId) {
                            return result[0];
                        }

                        return device;
                    });

                    this.setState({ devices });
                }
            )
        );
    };

    archiveDevice = deviceId => {
        const { user, dispatch } = this.props;

        message.loading("Archiving device...");

        dispatch(
            createData(
                API_RESOURCES.UPDATE_DEVICE_INFO,
                {
                    deviceId,
                    updateRequest: {
                        updateMethod: "SET",
                        relatedAttributes: "archived",
                        relatedData: true,
                    },
                },
                "removeDevice",
                () => {
                    message.success("Device archived");

                    // Update the viewableDevice of the user
                    const { dispatch } = this.props;
                    const { devices } = this.state;
                    let viewableDevice = devices.map(device => device.deviceId);
                    viewableDevice = viewableDevice.filter(
                        id => id !== deviceId
                    );
                    dispatch(
                        createData(
                            API_RESOURCES.UPDATE_USER_INFO,
                            {
                                firebaseUserId: user.user.firebaseUserId,
                                updateRequest: {
                                    updateMethod: "SET",
                                    relatedAttributes: "viewableDevice",
                                    relatedData: viewableDevice,
                                },
                            },
                            "viewableDevice",
                            () => {
                                this.getDevicesInfo(viewableDevice);
                                dispatch(
                                    requestData(
                                        API_RESOURCES.GET_USER_INFO,
                                        {
                                            firebaseUserId:
                                                user.user.firebaseUserId,
                                        },
                                        "user",
                                        result => {
                                            result.accountType = getCurrentUserRole(
                                                result.hierarchy
                                            );
                                            dispatch(saveUserInfo(result));
                                        }
                                    )
                                );
                            }
                        )
                    );
                }
            )
        );
    };

    navigateToAddNew = () => {
        this.props.dispatch(moveToPage("/Distributor Dashboard/AddNewDevice"));
    };

    navigateToEdit = deviceId =>
        this.props.dispatch(
            moveToPage("/Distributor Dashboard/Edit", {
                deviceId,
            })
        );

    render() {
        let { deviceList, actionsDisabled } = this.props;
        if (!deviceList) deviceList = [];
        let devices = this.state.devices;
        // console.log('DEVICES', devices);

        const columns = [
            {
                title: "Plate",
                dataIndex: "plateNumber",
            },
            {
                title: "IMEI",
                dataIndex: "deviceId",
            },
            {
                title: "SIM",
                dataIndex: "simCardNumber",
            },
            {
                title: "Expiry",
                dataIndex: "expiryDate",
                render: text => (text = text.slice(0, 10).replace("T", " ")), //show date only
                // render: text => text.slice(0, 19).replace("T", " "), //show datetime only
                // render: text =>
                //     moment(Number(text)).format("YYYY-MM-DDThh:mm:ss"),
                // sorter: (a, b) => a.expiryDate - b.expiryDate,
                // sortDirections: ["ascend", "descend"],
                // defaultSortOrder: "ascend",
            },
        ];

        // Add the "Actions" column only if not actionsDisabled
        // if (!actionsDisabled) {
        if (false) {
            columns.push({
                title: "Actions",
                render: device => (
                    <Button.Group>
                        <button className="transparent-button">
                            <Icon
                                type="reload"
                                onClick={() =>
                                    this.renewDevice(device.deviceId)
                                }
                            />
                        </button>
                        <button className="transparent-button">
                            <Icon
                                type="edit"
                                onClick={() =>
                                    this.navigateToEdit(device.deviceId)
                                }
                            />
                        </button>
                        <Popconfirm
                            placement="right"
                            title="Are you sure?"
                            onConfirm={() =>
                                this.archiveDevice(device.deviceId)
                            }
                            okText="Yes"
                            cancelText="No"
                        >
                            <button className="transparent-button">
                                <Icon type="delete" />
                            </button>
                        </Popconfirm>
                    </Button.Group>
                ),
            });
        }

        return (
            <div
                className="scroll-div"
                style={{
                    width: "80%",
                    margin: "0 10%",
                }}
            >
                <div
                    style={{
                        padding: 5,
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    {/* !actionsDisabled */ false && (
                        <PrimaryButton
                            icon="plus"
                            onClick={this.navigateToAddNew}
                        >
                            Add New
                        </PrimaryButton>
                    )}
                </div>
                <div>
                    {devices.length > 0 ? (
                        <Table dataSource={devices} columns={columns} />
                    ) : (
                        <EmptyIndicator />
                    )}
                </div>
            </div>
        );
    }
}

export default connect(appStore => appStore)(OwningDeviceList);
