import React from "react";
import "../App.css";
import { connect } from "react-redux";
import {
  Form,
  Icon,
  Input,
  InputNumber,
  Button,
  Select,
  message,
  DatePicker
} from "antd";
import { goBackToPrev } from "../navigation/navigationService";
import { dispatchWithUserData } from "../services/redux/actions/user";
import { requestData, createData } from "../services/redux/actions/request";
import {
  API_RESOURCES,
  DEVICE_TYPE,
  SENSOR_TYPE,
  ROLE_TYPE
} from "../constants";
import { validateImei } from "../services/validator/validateImei";
import { validatePhoneInput } from "./../services/validator/validatePhone";
import { getCurrentUserRole } from "../services/hierarchy/hierarchy";
import showSecondarySideabar from "../components/Layout/helpers/showSecondarySidebar";
import { moveToPage } from "../navigation/navigationService";
import { buildAbility } from "../services/auth/accessRight";
import { FEATURE } from "../constants";
import { PrimaryButton } from "../components/Button";

const FormItem = Form.Item;

class AddDeviceForm extends React.Component {
  state = {
    deviceId: "",
    vehiclePlate: "",
    tankCapacity: "",
    userFirebaseUserId: "",
    vendorFirebaseUserId: "",
    simCardNumber: "",
    expiryDate: "",
    deviceType: "",
    sensorType: "",

    userList: [],
    vendorDisplayName: ""
  };

  submitForm = () => {
    let isValid = true;

    if (!validateImei(this.state.deviceId)) {
      message.error("Invalid Device ID");
      isValid = false;
    }

    if (this.state.vehiclePlate === "") {
      message.error("Invalid Vehicle Plate");
      isValid = false;
    }

    if (this.state.tankCapacity === "") {
      message.error("Invalid tank capacity");
      isValid = false;
    }

    if (
      !this.state.userFirebaseUserId ||
      this.state.userFirebaseUserId === ""
    ) {
      message.error("Invalid Assigned User");
      isValid = false;
    }

    if (
      !this.state.vendorFirebaseUserId ||
      this.state.vendorFirebaseUserId === ""
    ) {
      message.error("Invalid Servicer");
      isValid = false;
    }

    if (!validatePhoneInput(this.state.simCardNumber)) {
      message.error("Invalid SIM card number");
      isValid = false;
    }

    if (!this.state.expiryDate || this.state.expiryDate === "") {
      message.error("Invalid Expiry Date");
      isValid = false;
    }

    if (!this.state.deviceType || this.state.deviceType === "") {
      message.error("Invalid Device Type");
      isValid = false;
    }

    if (!this.state.sensorType || this.state.sensorType === "") {
      message.error("Invalid Sensor Type");
      isValid = false;
    }

    if (isValid) {
      this.props.onConfirm({
        deviceId: this.state.deviceId,
        plateNumber: this.state.vehiclePlate,
        tankCapacity: this.state.tankCapacity,
        firebaseUserId: this.state.userFirebaseUserId,
        vendorFirebaseUserId: this.state.vendorFirebaseUserId,
        simCardNumber: this.state.simCardNumber,
        expiryDate: this.state.expiryDate,
        deviceType: this.state.deviceType,
        sensorType: this.state.sensorType
      });
    }
  };

  componentDidMount() {
    this.props.dispatch(
      dispatchWithUserData(() => {
        let { user } = this.props.user;
        if (!user) user = {};

        if (
          !buildAbility(user.accountType).can("read", FEATURE.OPERATOR_ACCOUNT)
        ) {
          this.props.dispatch(moveToPage("/Distributor Dashboard"));
          return;
        }

        this.setState({
          vendorFirebaseUserId: user.firebaseUserId,
          vendorDisplayName: user.displayName
        });
        this.props.dispatch(
          requestData(
            API_RESOURCES.QUERY_HIERARCHY,
            { hierarchy: user.hierarchy },
            "userHierarchy",
            () => {
              let { userHierarchy } = this.props.request.data;
              if (!userHierarchy.result) userHierarchy.result = [];

              userHierarchy = userHierarchy.result;
              this.setState({ userList: userHierarchy });
            }
          )
        );
      })
    );
  }

  render() {
    return (
      <Form className="login-form">
        <FormItem
          label="Device ID"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Input
            placeholder="860124123578906"
            value={this.state.deviceId}
            onChange={e => this.setState({ deviceId: e.target.value })}
          />
        </FormItem>
        <FormItem
          label="Vehicle Plate"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Input
            placeholder="WWW 1288"
            value={this.state.vehiclePlate}
            onChange={e => this.setState({ vehiclePlate: e.target.value })}
          />
        </FormItem>
        <FormItem
          label="Tank (liter)"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <InputNumber
            placeholder="30.5"
            value={this.state.tankCapacity}
            onChange={value => this.setState({ tankCapacity: value })}
          />
        </FormItem>
        <FormItem
          label="User:"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Select
            placeholder="Select a user device is assigned to"
            onChange={value => this.setState({ userFirebaseUserId: value })}
          >
            {this.state.userList.map(user => {
              return (
                <Select.Option
                  key={user.firebaseUserId}
                  value={user.firebaseUserId}
                >
                  {user.displayName}
                </Select.Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem
          label="Servicer"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Input
            disabled
            placeholder="GPS Fleet"
            value={this.state.vendorDisplayName}
          />
        </FormItem>
        <FormItem
          label="SIM Contract"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Input
            placeholder="+60182231122"
            value={this.state.simCardNumber}
            onChange={e => this.setState({ simCardNumber: e.target.value })}
          />
        </FormItem>
        <FormItem
          label="Expiry Date"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            onChange={dateObject => {
              this.setState({
                expiryDate: new Date(dateObject.toDate()).getTime()
              });
            }}
          />
        </FormItem>
        <FormItem
          label="Device Type:"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Select
            placeholder="Select a device type"
            onChange={value => this.setState({ deviceType: value })}
          >
            {DEVICE_TYPE.map(deviceType => {
              return (
                <Select.Option key={deviceType.value} value={deviceType.value}>
                  {deviceType.label}
                </Select.Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem
          label="Sensor:"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Select
            placeholder="Select a sensor type"
            onChange={value => this.setState({ sensorType: value })}
          >
            {SENSOR_TYPE.map(sensorType => {
              return (
                <Select.Option key={sensorType.value} value={sensorType.value}>
                  {sensorType.label}
                </Select.Option>
              );
            })}
          </Select>
        </FormItem>

        <div style={{ position: "absolute", bottom: 20, right: 25 }}>
          <Button
            type="danger"
            className="login-form-button"
            onClick={this.props.onCancel}
          >
            Cancel
          </Button>

          <PrimaryButton onClick={this.submitForm}>Add New</PrimaryButton>
        </div>
      </Form>
    );
  }
}

class DistributorAddNewPage extends React.Component {
  copyViewableDevice = (user, operator) => {
    console.log("user :", user);
    console.log("operator :", operator);

    const loadingIndicator = message.loading(
      `Copying data for ${operator.name}`
    );

    const { viewableDevice = [] } = user;
    const { firebaseUserId } = operator;

    this.props.dispatch(
      createData(
        API_RESOURCES.UPDATE_USER_INFO,
        {
          firebaseUserId,
          updateRequest: {
            updateMethod: "SET",
            relatedAttributes: "viewableDevice",
            relatedData: viewableDevice
          }
        },
        "updateOperatorDevice",
        updateOperatorResult => {
          console.log("updateOperatorResult :", updateOperatorResult);
          loadingIndicator();
          message.success(`Copied data for ${operator.name}`);
        }
      )
    );
  };

  assignDeviceToOperator = firebaseUserId => {
    this.props.dispatch(
      requestData(
        API_RESOURCES.GET_USER_INFO,
        { firebaseUserId },
        `user${firebaseUserId}`,
        queryUser => {
          const { hierarchy } = queryUser;

          console.log("query hierarchy :", hierarchy);

          this.props.dispatch(
            requestData(
              API_RESOURCES.QUERY_HIERARCHY,
              { hierarchy: hierarchy },
              "queryHierarchy",
              (hierarchyArray = []) => {
                hierarchyArray.map(hierarchyUser => {
                  if (
                    getCurrentUserRole(hierarchyUser.hierarchy) ===
                    ROLE_TYPE.OPERATOR
                  ) {
                    this.copyViewableDevice(queryUser, {
                      name: hierarchyUser.displayName,
                      firebaseUserId: hierarchyUser.firebaseUserId
                    });
                  }
                });

                this.navigateBack();
              }
            )
          );
        }
      )
    );
  };

  navigateBack = () => {
    this.props.dispatch(goBackToPrev());
  };

  submitForm = data => {
    const loadingIndicator = message.loading("Creating device...");
    this.props.dispatch(
      createData(
        API_RESOURCES.REGISTER_DEVICE,
        { deviceInfo: data },
        "registerDevice",
        () => {
          loadingIndicator();
          this.assignDeviceToOperator(data.firebaseUserId);
        }
      )
    );
  };

  render() {
    return (
      <div className="page-container">
        <div
          className="page-header"
          style={{
            backgroundColor: this.props.style.pageHeaderColor,
            color: this.props.style.textColor
          }}
        >
          <div className="page-header-contents">
            <button className="transparent-button" onClick={this.navigateBack}>
              <Icon type="left" />
            </button>
            <div>Distributor Dashboard</div>
          </div>
        </div>

        <div
          className="common-header"
          style={{
            backgroundColor: this.props.style.tableHeaderColor,
            color: this.props.style.textColor
          }}
        >
          Add New Device
        </div>

        <div style={{ padding: "30px 20px 0px 20px" }}>
          <AddDeviceForm
            {...this.props}
            onCancel={this.navigateBack}
            onConfirm={this.submitForm}
          />
        </div>
      </div>
    );
  }
}

const ConnectedDistributorAddNewPage = connect(appStore => appStore)(
  DistributorAddNewPage
);

export default showSecondarySideabar(false)(ConnectedDistributorAddNewPage);
