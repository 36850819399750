import React from "react";
import PropTypes from "prop-types";
import { Menu, Icon } from "antd";
import "./SidebarItem.css";

const SidebarItem = props => {
  const { key, icon, label } = props;
  return (
    <Menu.Item key={key} {...props}>
      <Icon type={icon} style={{ fontSize: 24 }} />
      <span>{label}</span>
    </Menu.Item>
  );
};

SidebarItem.propTypes = {
  key: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default SidebarItem;
