import * as ActionTypes from "../action-types/notification";

export const notification = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.TRIGGER_BROWSER_NOTIFICATION: {
            return Object.assign({}, state, {
                title: action.title || "GPS Fleet",
                options: {
                    tag: new Date(Date.now()).getTime().toString(),
                    body: action.body || "",
                    icon: action.icon || ""
                }
            });
        }
        default: {
            return state;
        }
    }
};
