import * as ActionTypes from "../action-types/request";

const INITIAL_STATE = {
  data: {}
};

export const request = (state = INITIAL_STATE, action) => {
  let newState = state;

  switch (action.type) {
    case ActionTypes.REQUEST_DATA_START:
    case ActionTypes.REQUEST_DATA_LIST_START:
    case ActionTypes.CREATE_DATA_START:
    case ActionTypes.CREATE_DATA_LIST_START:
    case ActionTypes.UPDATE_DATA_START:
    case ActionTypes.REMOVE_DATA_START: {
      let resultState = {
        _loaded: false,
        _error: false,
        _errorMessage: null,
        _dataVersion:
          (state.data[action.target] &&
            state.data[action.target]._dataVersion) ||
          0,
        result: []
      };

      newState = Object.assign({}, state, {
        data: state.data
      });

      newState.data[action.target] = resultState;

      return newState;
    }
    case ActionTypes.REQUEST_DATA_SUCCESS:
    case ActionTypes.REQUEST_DATA_LIST_SUCCESS:
    case ActionTypes.CREATE_DATA_SUCCESS:
    case ActionTypes.CREATE_DATA_LIST_SUCCESS:
    case ActionTypes.UPDATE_DATA_SUCCESS:
    case ActionTypes.REMOVE_DATA_SUCCESS: {

      let resultState = {
        _loaded: true,
        _error: false,
        _errorMessage: null,
        _dataVersion:
          ((state.data[action.target] &&
            state.data[action.target]._dataVersion) ||
            0) + 1,
        result: action.result
      };

      newState = Object.assign({}, state, {
        data: state.data
      });

      newState.data[action.target] = resultState;

      return newState;
    }

    case ActionTypes.REQUEST_DATA_FAIL:
    case ActionTypes.REQUEST_DATA_LIST_FAIL:
    case ActionTypes.CREATE_DATA_FAIL:
    case ActionTypes.CREATE_DATA_LIST_FAIL:
    case ActionTypes.UPDATE_DATA_FAIL:
    case ActionTypes.REMOVE_DATA_FAIL: {
      let resultState = {
        _loaded: true,
        _error: true,
        _errorMessage: action.errorMessage,
        _dataVersion: state.data[action.target]._dataVersion,
        result: null
      };

      newState = Object.assign({}, state, {
        data: state.data
      });

      newState.data[action.target] = resultState;

      return newState;
    }

    /** POI CREATION CASES */
    /** start */
    case ActionTypes.CREATE_POI_START:
      //set _loaded, _error and _errorMessage to false, false and null respectively for the target
      //if result does not exists yet, initialize it to empty array
      //but don't reset result
      newState = {
        ...state,
        data: {
          ...state.data,
          poi: {
            ...state.data.poi,
            _loaded: false,
            _error: false,
            _errorMessage: null,
            result: state.data.poi ? state.data.poi.result : []
          }
        }
      };
      return newState;

    /** success*/
    case ActionTypes.CREATE_POI_SUCCESS:
      //set _loaded to true
      //increment _dataVersion by 1
      //concat payload to result
      newState = {
        ...state,
        data: {
          ...state.data,
          poi: {
            ...state.data.poi,
            _loaded: true,
            _dataVersion: state.data.poi._dataVersion + 1,
            result: [...state.data.poi.result, action.result]
          }
        }
      };

      return newState;

    /** failure */
    case ActionTypes.CREATE_POI_FAIL:
      //set _loaded, _error, _errorMessage to true, true and action.errorMessage respectively
      newState = {
        ...state,
        data: {
          ...state.data,
          poi: {
            ...state.data.poi,
            _loaded: true,
            _error: true,
            _errorMessage: action.errorMessage
          }
        }
      };

      return newState;

    /** GEOFENCE CREATION CASES */
    /** start */
    case ActionTypes.CREATE_GEOFENCE_START:
      //set _loaded, _error and _errorMessage to false, false and null respectively for the target
      //if result does not exists yet, initialize it to empty array
      //but don't reset result
      newState = {
        ...state,
        data: {
          ...state.data,
          shape: {
            ...state.data.shape,
            _loaded: false,
            _error: false,
            _errorMessage: null,
            result: state.data.shape ? state.data.shape.result : []
          }
        }
      };
      return newState;

    /** success*/
    case ActionTypes.CREATE_GEOFENCE_SUCCESS:
      //set _loaded to true
      //increment _dataVersion by 1
      //concat payload to result
      newState = {
        ...state,
        data: {
          ...state.data,
          shape: {
            ...state.data.shape,
            _loaded: true,
            _dataVersion: state.data.shape._dataVersion + 1,
            result: [...state.data.shape.result, ...action.result] //n/b: geofence data is an array
          }
        }
      };

      return newState;

    /** failure */
    case ActionTypes.CREATE_GEOFENCE_FAIL:
      //set _loaded, _error, _errorMessage to true, true and action.errorMessage respectively
      newState = {
        ...state,
        data: {
          ...state.data,
          shape: {
            ...state.data.shape,
            _loaded: true,
            _error: true,
            _errorMessage: action.errorMessage
          }
        }
      };

      return newState;

    /** DEFAULT CASE */
    default: {
      return state;
    }
  }
};
