import * as ActionTypes from "../action-types/mapControl";
import {
    LOCATE_MODE,
    PLAYBACK_MODE,
    DRAW_MODE,
    POLYGON,
    MARKER,
    DEFAULT_MAP_CENTER,
} from "../../../components/google-maps/MapConstants";
import { Action } from "rxjs/internal/scheduler/Action";

export const mapControl = (state = { mode: LOCATE_MODE }, action) => {
    switch (action.type) {
        case ActionTypes.SWITCH_TO_LOCATE_MODE: {
            if (!Array.isArray(action.markerList)) action.markerList = [];
            if (!Array.isArray(action.shapeList)) action.shapeList = [];
            if (!Array.isArray(action.poiList)) action.poiList = [];

            return Object.assign({}, state, {
                mode: LOCATE_MODE,
                markerList: action.markerList || [],
                shapeList: action.shapeList || [],
                poiList: action.poiList || [],
            });
        }
        case ActionTypes.SWITCH_TO_PLAYBACK_MODE: {
            if (!Array.isArray(action.pathCoordinates))
                action.pathCoordinates = [];

            return Object.assign({}, state, {
                mode: PLAYBACK_MODE,
                pathCoordinates: action.pathCoordinates || [],
                markerList: action.markerList || [],
            });
        }
        case ActionTypes.SWITCH_TO_DRAW_POINT_MODE: {
            return Object.assign({}, state, {
                mode: DRAW_MODE,
                drawType: MARKER,
                poiCoordinate: null,
                poiAddress: null,
            });
        }
        case ActionTypes.SWITCH_TO_DRAW_POLYGON_MODE: {
            return Object.assign({}, state, {
                mode: DRAW_MODE,
                drawType: POLYGON,
                shapeCoordinateList: [],
            });
        }
        case ActionTypes.PASS_POINT_COORDINATE: {
            let isValidCoordinate = true;
            if (!action.coordinate) isValidCoordinate = false;
            else {
                if (!action.coordinate.lat && action.coordinate.lat !== 0)
                    isValidCoordinate = false;
                if (!action.coordinate.lng && action.coordinate.lng !== 0)
                    isValidCoordinate = false;
            }

            if (!isValidCoordinate)
                action.coordinate = state.poiCoordinate || {};

            return Object.assign({}, state, {
                poiCoordinate: action.coordinate,
                poiAddress: action.address,
            });
        }
        case ActionTypes.PASS_POLYGON_COORDINATE_LIST: {
            if (!Array.isArray(state.shapeCoordinateList))
                state.shapeCoordinateList = [];

            if (!Array.isArray(action.coordinateList))
                action.coordinateList = false;

            return Object.assign({}, state, {
                shapeCoordinateList: action.coordinateList
                    ? [...state.shapeCoordinateList, action.coordinateList]
                    : state.shapeCoordinateList,
            });
        }
        case ActionTypes.TOGGLE_PLAYBACK_INFO_BOX: {
            return Object.assign({}, state, {
                markerList: state.markerList.map(marker => {
                    if (marker.id === action.markerId) {
                        return { ...marker, showInfoBox: !marker.showInfoBox };
                    } else {
                        return marker;
                    }
                }),
            });
        }
        case ActionTypes.OPEN_PLAYBACK_INFO_BOX: {
            return Object.assign({}, state, {
                markerList: state.markerList.map(marker => {
                    if (marker.id === action.markerId) {
                        return { ...marker, showInfoBox: true };
                    } else {
                        return marker;
                    }
                }),
            });
        }
        default: {
            return state;
        }
    }
};
