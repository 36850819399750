import { AbilityBuilder } from "@casl/ability";
import { FEATURE, ROLE_TYPE } from "../../constants";

/**
 * Defines the access right for the user
 * @param {string} roleType Role of the user
 */
export const buildAbility = roleType => {
  if (typeof roleType === 'undefined') {
    roleType = ROLE_TYPE.BUSINESS_OWNER
  }
  switch (roleType) {
    case ROLE_TYPE.BUSINESS_OWNER: {
      return AbilityBuilder.define((can, cannot) => {
        can("manage", FEATURE.VEHICLE_LOCATION);
        can("manage", FEATURE.FLEET_TRACKING);
        can("manage", FEATURE.SUMMARY);
        can("manage", FEATURE.REPORT);
        can("manage", FEATURE.ALERTS);
        can("manage", FEATURE.POI);
        can("manage", FEATURE.POI);
        can("manage", FEATURE.GEOFENCE);
        can("manage", FEATURE.GEOFENCE);
        // can("manage", FEATURE.CUSTOMER); //newly added
        // can("manage", FEATURE.OPERATOR_ACCOUNT);
        // can("manage", FEATURE.OPERATOR_ACCOUNT);
        can("manage", FEATURE.DISTRIBUTOR_DASHBOARD);
        can("manage", FEATURE.DISTRIBUTOR_DASHBOARD);
        can("manage", FEATURE.CALLED_REPORT);
        can("manage", FEATURE.NOTIFICATIONS);
        can("manage", FEATURE.PROFILE);
        can("manage", FEATURE.PROFILE);
        can("manage", FEATURE.PREFERENCES);
        can("manage", FEATURE.PREFERENCES);
        can("manage", FEATURE.ABOUT);
        // can("read", FEATURE.DEV);
        can("manage", FEATURE.CASES);
        can("manage", FEATURE.CASES);
      });
    }
    case ROLE_TYPE.BUSINESS_ADMIN: {
      return AbilityBuilder.define((can, cannot) => {
        can("manage", FEATURE.VEHICLE_LOCATION);
        can("manage", FEATURE.FLEET_TRACKING);
        can("manage", FEATURE.SUMMARY);
        can("manage", FEATURE.REPORT);
        can("manage", FEATURE.ALERTS);
        can("manage", FEATURE.POI);
        can("manage", FEATURE.POI);
        can("manage", FEATURE.GEOFENCE);
        can("manage", FEATURE.GEOFENCE);
        // can("manage", FEATURE.CUSTOMER); //newly added
        // can("manage", FEATURE.OPERATOR_ACCOUNT);
        // can("manage", FEATURE.OPERATOR_ACCOUNT);
        can("manage", FEATURE.DISTRIBUTOR_DASHBOARD);
        can("manage", FEATURE.DISTRIBUTOR_DASHBOARD);
        can("manage", FEATURE.CALLED_REPORT);
        can("manage", FEATURE.NOTIFICATIONS);
        can("manage", FEATURE.PROFILE);
        can("manage", FEATURE.PROFILE);
        can("manage", FEATURE.PREFERENCES);
        can("manage", FEATURE.PREFERENCES);
        can("manage", FEATURE.ABOUT);
        // can("read", FEATURE.DEV);
        can("manage", FEATURE.CASES);
        can("manage", FEATURE.CASES);
      });
    }
    case ROLE_TYPE.DISTRIBUTOR: {
      return AbilityBuilder.define((can, cannot) => {
        can("read", FEATURE.VEHICLE_LOCATION);
        can("read", FEATURE.FLEET_TRACKING);
        can("read", FEATURE.SUMMARY);
        can("read", FEATURE.REPORT);
        can("read", FEATURE.ALERTS);
        can("read", FEATURE.POI);
        can("manage", FEATURE.POI);
        can("read", FEATURE.GEOFENCE);
        can("manage", FEATURE.GEOFENCE);
        // can("read", FEATURE.CUSTOMER); //newly added
        // can("manage", FEATURE.CUSTOMER); //newly added
        // can("read", FEATURE.OPERATOR_ACCOUNT);
        // can("manage", FEATURE.OPERATOR_ACCOUNT);
        can("read", FEATURE.DISTRIBUTOR_DASHBOARD);
        can("manage", FEATURE.DISTRIBUTOR_DASHBOARD);
        can("read", FEATURE.CALLED_REPORT);
        can("read", FEATURE.NOTIFICATIONS);
        can("read", FEATURE.PROFILE);
        can("manage", FEATURE.PROFILE);
        can("read", FEATURE.PREFERENCES);
        can("manage", FEATURE.PREFERENCES);
        can("read", FEATURE.ABOUT);
        // can("read", FEATURE.DEV);
        can("read", FEATURE.CASES);
        can("manage", FEATURE.CASES);
      });
    }
    case ROLE_TYPE.FLEET_OWNER: {
      return AbilityBuilder.define((can, cannot) => {
        can("read", FEATURE.VEHICLE_LOCATION);
        can("read", FEATURE.FLEET_TRACKING);
        can("read", FEATURE.SUMMARY);
        can("read", FEATURE.REPORT);
        can("read", FEATURE.ALERTS);
        can("read", FEATURE.POI);
        can("manage", FEATURE.POI);
        can("read", FEATURE.GEOFENCE);
        can("manage", FEATURE.GEOFENCE);
        // can("read", FEATURE.CUSTOMER); //newly added
        // can("read", FEATURE.OPERATOR_ACCOUNT);
        // can("manage", FEATURE.OPERATOR_ACCOUNT);
        can("read", FEATURE.DISTRIBUTOR_DASHBOARD);
        can("manage", FEATURE.DISTRIBUTOR_DASHBOARD);
        can("read", FEATURE.CALLED_REPORT);
        can("read", FEATURE.NOTIFICATIONS);
        can("read", FEATURE.PROFILE);
        can("manage", FEATURE.PROFILE);
        can("read", FEATURE.PREFERENCES);
        can("manage", FEATURE.PREFERENCES);
        can("read", FEATURE.ABOUT);
        // can("read", FEATURE.DEV);
        can("read", FEATURE.CASES);
        can("manage", FEATURE.CASES);
      });
    }
    case ROLE_TYPE.FLEET_MANAGER: {
      return AbilityBuilder.define((can, cannot) => {
        can("read", FEATURE.VEHICLE_LOCATION);
        can("read", FEATURE.FLEET_TRACKING);
        can("read", FEATURE.SUMMARY);
        can("read", FEATURE.REPORT);
        can("read", FEATURE.ALERTS);
        can("read", FEATURE.POI);
        //can("manage", FEATURE.POI);
        can("read", FEATURE.GEOFENCE);
        //can("manage", FEATURE.GEOFENCE);
        // can("read", FEATURE.CUSTOMER); //newly added
        // can("read", FEATURE.OPERATOR_ACCOUNT);
        // can("manage", FEATURE.OPERATOR_ACCOUNT);
        can("read", FEATURE.DISTRIBUTOR_DASHBOARD);
        //can("manage", FEATURE.DISTRIBUTOR_DASHBOARD);
        can("read", FEATURE.CALLED_REPORT);
        can("read", FEATURE.NOTIFICATIONS);
        can("read", FEATURE.PROFILE);
        can("manage", FEATURE.PROFILE);
        can("read", FEATURE.PREFERENCES);
        can("manage", FEATURE.PREFERENCES);
        can("read", FEATURE.ABOUT);
        // can("read", FEATURE.DEV);
        can("read", FEATURE.CASES);
        can("manage", FEATURE.CASES);
      });
    }
    case ROLE_TYPE.OPERATOR: {
      return AbilityBuilder.define((can, cannot) => {
        can("read", FEATURE.VEHICLE_LOCATION);
        can("read", FEATURE.FLEET_TRACKING);
        can("read", FEATURE.SUMMARY);
        can("read", FEATURE.REPORT);
        can("read", FEATURE.ALERTS);
        can("read", FEATURE.POI);
        //can("manage", FEATURE.POI);
        can("read", FEATURE.GEOFENCE);
        //can("manage", FEATURE.GEOFENCE);
        // can("read", FEATURE.CUSTOMER); //newly added
        //can("read", FEATURE.OPERATOR_ACCOUNT);
        //can("manage", FEATURE.OPERATOR_ACCOUNT);
        can("read", FEATURE.DISTRIBUTOR_DASHBOARD);
        //can("manage", FEATURE.DISTRIBUTOR_DASHBOARD);
        can("read", FEATURE.CALLED_REPORT);
        can("read", FEATURE.NOTIFICATIONS);
        can("read", FEATURE.PROFILE);
        //can("manage", FEATURE.PROFILE);
        can("read", FEATURE.PREFERENCES);
        //can("manage", FEATURE.PREFERENCES);
        can("read", FEATURE.ABOUT);
        // can("read", FEATURE.DEV);
        can("read", FEATURE.CASES);
        // can("manage", FEATURE.CASES);
      });
    }
    default: {
      return AbilityBuilder.define((can, cannot) => {
        can("read", FEATURE.VEHICLE_LOCATION);
        can("read", FEATURE.FLEET_TRACKING);
        can("read", FEATURE.SUMMARY);
        //can("read", FEATURE.REPORT);
        //can("read", FEATURE.ALERTS);
        //can("read", FEATURE.POI);
        //can("manage", FEATURE.POI);
        //can("read", FEATURE.GEOFENCE);
        //can("manage", FEATURE.GEOFENCE);
        //can("read", FEATURE.OPERATOR_ACCOUNT);
        //can("manage", FEATURE.OPERATOR_ACCOUNT);
        //can("read", FEATURE.DISTRIBUTOR_DASHBOARD);
        //can("manage", FEATURE.DISTRIBUTOR_DASHBOARD);
        //can("read", FEATURE.CALLED_REPORT);
        //can("read", FEATURE.NOTIFICATIONS);
        can("read", FEATURE.PROFILE);
        //can("manage", FEATURE.PROFILE);
        can("read", FEATURE.PREFERENCES);
        //can("manage", FEATURE.PREFERENCES);
        can("read", FEATURE.ABOUT);
        // can("read", FEATURE.DEV);
      });
    }
  }
};
