import React from "react";
import PropTypes from "prop-types";

const SVG = ({ size, style, viewBox, className, fill, name }) => {
    const getPath = (name, props) => {
        switch (name) {
            case "car-avatar":
                return [
                    <circle cx={25} cy={25} r={25} fill={props.fill} />,
                    <path
                        d="M23.4167 23.4286V18.7143H25.3911C26.7876 18.7143 27.3465 19.181 28.2854 20.1427C29.0011 20.8734 31.3333 23.4286 31.3333 23.4286H23.4167ZM21.8333 18.7143H18.2819C17.1926 18.7143 16.6178 19.0631 16.1017 19.6273C15.6013 20.1741 14.6846 21.117 13.7172 22.2531C16.4389 23.2651 20.1376 23.4286 21.8333 23.4286V18.7143ZM44 26.8244C44 22.3883 40.7858 21.4659 39.5762 21.1186C37.768 20.5984 36.9573 20.3721 34.7771 19.8709C33.9062 19.0144 31.5281 16.6714 30.9945 16.1371L30.8536 15.9941C29.7991 14.9271 28.6037 14 25.8312 14H13.7346C12.8685 14 12.4822 14.7166 13.3324 15.4834C12.4869 16.2094 10.2544 18.1643 9.62742 18.796C7.43608 20.9991 6 22.6931 6 26.0293C6 29.8966 8.15492 32.7204 11.0334 32.8367C11.6826 34.6737 13.4274 36 15.5 36C17.5647 36 19.3048 34.6831 19.9587 32.8571H30.0397C30.6937 34.6831 32.4338 36 34.4984 36C36.5694 36 38.3127 34.6753 38.9634 32.8414C42.0256 32.7534 44 30.4764 44 26.8244ZM15.5 33.1714C14.4518 33.1714 13.6 32.326 13.6 31.2857C13.6 30.2454 14.4518 29.4 15.5 29.4C16.5482 29.4 17.4 30.2454 17.4 31.2857C17.4 32.326 16.5482 33.1714 15.5 33.1714ZM34.5 33.1714C33.4518 33.1714 32.6 32.326 32.6 31.2857C32.6 30.2454 33.4518 29.4 34.5 29.4C35.5482 29.4 36.4 30.2454 36.4 31.2857C36.4 32.326 35.5482 33.1714 34.5 33.1714ZM38.984 29.7929C38.3523 27.926 36.5948 26.5714 34.5 26.5714C32.3989 26.5714 30.6367 27.9354 30.0097 29.8117H19.9903C19.3633 27.9354 17.6011 26.5714 15.5 26.5714C13.4116 26.5714 11.6588 27.9181 11.0208 29.7771C10.0929 29.598 9.16667 28.1476 9.16667 26.0293C9.16667 23.9314 9.91083 22.9839 11.8805 21.0023C13.0284 19.8473 13.9547 19.1024 15.4636 17.8186C16.0019 17.3613 16.6891 17.1397 17.3984 17.1429H25.589C27.5207 17.1429 28.0859 17.7479 28.9393 18.5446C29.6028 19.1621 33.1858 22.7324 33.1858 22.7324C33.1858 22.7324 36.8323 23.603 38.6958 24.1389C40.0258 24.5207 40.8333 24.8916 40.8333 26.826C40.8333 28.7054 40.2063 29.6954 38.984 29.7929Z"
                        fill="white"
                    />,
                ];
            case "on-map-starting-point":
                return [
                    <circle
                        cx="16"
                        cy="16"
                        r="14"
                        fill="white"
                        stroke="#FF0000"
                        stroke-width="4"
                    />,
                    <path
                        d="M18.585 43.6426C18.585 43.1445 18.4092 42.7637 18.0576 42.5C17.7061 42.2305 17.0732 41.9492 16.1592 41.6562C15.2451 41.3574 14.5215 41.0645 13.9883 40.7773C12.5352 39.9922 11.8086 38.9346 11.8086 37.6045C11.8086 36.9131 12.002 36.2979 12.3887 35.7588C12.7812 35.2139 13.3408 34.7891 14.0674 34.4844C14.7998 34.1797 15.6201 34.0273 16.5283 34.0273C17.4424 34.0273 18.2568 34.1943 18.9717 34.5283C19.6865 34.8564 20.2402 35.3223 20.6328 35.9258C21.0312 36.5293 21.2305 37.2148 21.2305 37.9824H18.5938C18.5938 37.3965 18.4092 36.9424 18.04 36.6201C17.6709 36.292 17.1523 36.1279 16.4844 36.1279C15.8398 36.1279 15.3389 36.2656 14.9814 36.541C14.624 36.8105 14.4453 37.168 14.4453 37.6133C14.4453 38.0293 14.6533 38.3779 15.0693 38.6592C15.4912 38.9404 16.1094 39.2041 16.9238 39.4502C18.4238 39.9014 19.5166 40.4609 20.2021 41.1289C20.8877 41.7969 21.2305 42.6289 21.2305 43.625C21.2305 44.7324 20.8115 45.6025 19.9736 46.2354C19.1357 46.8623 18.0078 47.1758 16.5898 47.1758C15.6055 47.1758 14.709 46.9971 13.9004 46.6396C13.0918 46.2764 12.4736 45.7812 12.0459 45.1543C11.624 44.5273 11.4131 43.8008 11.4131 42.9746H14.0586C14.0586 44.3867 14.9023 45.0928 16.5898 45.0928C17.2168 45.0928 17.7061 44.9668 18.0576 44.7148C18.4092 44.457 18.585 44.0996 18.585 43.6426Z"
                        fill="#FF0000"
                    />,
                ];
            case "on-map-ending-point":
                return [
                    <circle
                        cx="16"
                        cy="16"
                        r="14"
                        fill="white"
                        stroke="#FF0000"
                        stroke-width="4"
                    />,
                    <path
                        d="M19.8945 41.4541H14.832V44.8818H20.7734V47H12.1953V34.2031H20.7559V36.3389H14.832V39.3887H19.8945V41.4541Z"
                        fill="#FF0000"
                    />,
                ];
        }
    };
    return (
        <svg
            width={size}
            style={style}
            height={size}
            viewBox={viewBox}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            {getPath(name, { fill })}
        </svg>
    );
};

SVG.propTypes = {
    style: PropTypes.object,
    fill: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    viewBox: PropTypes.string,
};

SVG.defaultProps = {
    style: {},
    fill: "#fff",
    size: "100%",
    className: "",
    viewBox: "0 0 32 32",
};

export default SVG;
