import React from "react";
import "../App.css";
import { connect } from "react-redux";
import { dispatchWithUserData } from "../services/redux/actions/user";
import DevicePanel from "../components/custom-components/business/DevicePanel";
import { LoadingIndicator } from "../components/custom-components/LoadingIndicator";
import Page from "../components/Page";
import showSecondarySidebar from "../components/Layout/helpers/showSecondarySidebar";
import { buildAbility } from "../services/auth/accessRight";
import { FEATURE } from "../constants";
import { moveToPage } from "../navigation/navigationService";

class DashBoard extends React.Component {
  state = {
    _loaded: false,
    actionsDisabled: false
  };

  componentDidMount() {
    // const { dispatch } = this.props;
    // dispatch(
    //   dispatchWithUserData(() => {
    //     let { user } = this.props.user;
    //     let actionsDisabled = !buildAbility((user || {}).accountType).can(
    //       "manage",
    //       FEATURE.DISTRIBUTOR_DASHBOARD
    //     );

    //     if (
    //       !buildAbility((user || {}).accountType).can(
    //         "read",
    //         FEATURE.DISTRIBUTOR_DASHBOARD
    //       )
    //     ) {
    //       this.props.dispatch(moveToPage("/"));
    //       return;
    //     }

    //     this.setState({ _loaded: true, actionsDisabled });
    //   })
    // );
  }

  render() {
    const { _loaded, actionsDisabled } = this.state;
    const { match } = this.props;
    let { user } = this.props.user;
    if (!user) user = {};

    return (
      <div className="page-container">
        <Page title="Device List">
          <DevicePanel
            firebaseUserId={user.firebaseUserId}
            match={match}
            actionsDisabled={actionsDisabled}
          />
          {/* {_loaded ? (
            <DevicePanel
              firebaseUserId={user.firebaseUserId}
              match={match}
              actionsDisabled={actionsDisabled}
            />
          ) : (
              <LoadingIndicator />
            )} */}
        </Page>
      </div>
    );
  }
}

const ConnectedDashboard = connect(appStore => appStore)(DashBoard);

export default showSecondarySidebar(false)(ConnectedDashboard);
