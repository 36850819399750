import config from "./../../config";
import { setupClient } from "./RestConfig";
import { HTTP_METHOD } from "../../constants";

/**
 * Requests data from server, similar to GET
 * @param {string} resource API resource to request
 * @param {object} params Additional parameters for request
 * @param {string} optionalAddress Base address for test purpose
 */
export const getData = async (
  resource,
  params,
  optionalAddress = undefined
) => {
  let address = `${optionalAddress || config.baseAddress}${resource}`;

  const request = await setupClient(HTTP_METHOD.POST, params);

  // console.log("address :", address);
  // console.log("request :", request);

  return fetch(address, request);
};

/**
 * Posts data to the server, siilar to POST request
 * @param {string} resource API resource to request
 * @param {object} requestBody Data to be posted to the server
 * @param {string} optionalAddress Base address for test purpose
 */
export const postData = async (
  resource,
  requestBody,
  optionalAddress = undefined
) => {
  let address = `${optionalAddress || config.baseAddress}${resource}`;

  console.log("address :", address);
  console.log("requestBody :", requestBody);

  return fetch(address, await setupClient(HTTP_METHOD.POST, requestBody));
};

export const putData = (resource, params, requestBody) => {
  throw new Error("PUT Method not Implemented");
};

export const deleteData = (resource, params) => {
  throw new Error("DELETE Method not Implemented");
};
