import * as ActionTypes from "../action-types/user";
import { requestData } from "./request";
import { API_RESOURCES, ROLE_TYPE } from "../../../constants";
import { retrieveUserData } from "../../auth/authService";
import { getCurrentUserRole } from "../../hierarchy/hierarchy";

export const saveUserInfo = user => {
  return {
    type: ActionTypes.SAVE_USER_INFO,
    user
  };
};

export const deleteUserInfo = () => {
  return {
    type: ActionTypes.DELETE_USER_INFO
  };
};

export const dispatchWithUserData = (functionToDispatch = () => { }) => (
  dispatch,
  getState
) => {
  let store = getState();
  const { user } = store.user;

  if (user) {
    dispatch(functionToDispatch);
  } else {
    dispatch(
      requestData(
        API_RESOURCES.GET_USER_INFO,
        { firebaseUserId: retrieveUserData().uid },
        "user",
        result => {
          result.accountType = getCurrentUserRole(result.hierarchy);

          dispatch(saveUserInfo(result));
          functionToDispatch();
        }
      )
    );
  }
};
