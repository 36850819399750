import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { appStore } from "../../services/redux/reducers/index";
import Notification from "react-web-notification";
import { BrowserView } from "react-device-detect";

/**
 * Internal Notification Component for Browser Notification.
 *
 * Requires "notification" redux to be setup. Triggered when different tag for options prop
 * is detected.
 */
class BrowserNotification extends Component {
    render() {
        const { notification } = this.props;

        return (
            <BrowserView>
                <Notification
                    ignore={false}
                    timeout={5000}
                    title={(notification && notification.title) || "Xpand"}
                    options={notification && notification.options}
                />
            </BrowserView>
        );
    }
}

export default connect(appStore => appStore)(BrowserNotification);
