import React from "react";
import "../App.css";
import { connect } from "react-redux";
import { Form, Icon, Input, Button, Select, message } from "antd";
import { goBackToPrev } from "../navigation/navigationService";
import { retrieveUserData, createAccount } from "../services/auth/authService";
import { CREATE_ROLE_TYPE } from "./../constants";
import { validateEmailInput } from "./../services/validator/validateEmail";
import { validatePhoneInput } from "../services/validator/validatePhone";
import { moveToPage } from "../navigation/navigationService";
import { dispatchWithUserData } from "../services/redux/actions/user";
import { buildAbility } from "../services/auth/accessRight";
import { FEATURE } from "../constants";
import { PrimaryButton } from "../components/Button";

const FormItem = Form.Item;

class EditEmailForm extends React.Component {
  state = {
    displayName: "",
    email: "",
    password: "",
    phoneNumber: "",
    role: ""
  };

  submitForm = () => {
    let isValid = true;

    if (this.state.displayName === "") {
      message.error("Invalid Name");
      isValid = false;
    }

    if (!validateEmailInput(this.state.email)) {
      message.error("Invalid Email");
      isValid = false;
    }

    if (this.state.password === "") {
      message.error("Invalid Password");
      isValid = false;
    }

    if (!validatePhoneInput(this.state.phoneNumber)) {
      message.error("Invalid Phone Number");
      isValid = false;
    }

    if (this.state.role === "") {
      message.error("Invalid role");
      isValid = false;
    }

    if (isValid) {
      this.props.onConfirm({
        displayName: this.state.displayName,
        email: this.state.email,
        accountType: this.state.role,
        phoneNumber: this.state.phoneNumber,
        password: this.state.password
      });
    }
  };

  componentDidMount() {
    this.props.dispatch(
      dispatchWithUserData(() => {
        let { user } = this.props.user;

        if (
          !buildAbility((user || {}).accountType).can(
            "manage",
            FEATURE.CUSTOMER
          )
        ) {
          this.props.dispatch(moveToPage("/"));
          return;
        }
      })
    );
  }

  render() {
    const currentUser = retrieveUserData();

    return (
      <Form className="login-form">
        <FormItem label="Name" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}>
          <Input
            placeholder="Name"
            value={this.state.displayName}
            onChange={e => this.setState({ displayName: e.target.value })}
          />
        </FormItem>
        <FormItem
          label="Email"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Input
            placeholder="Email"
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })}
          />
        </FormItem>
        <FormItem
          label="Password"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Input
            type="password"
            placeholder="Password"
            value={this.state.password}
            onChange={e => this.setState({ password: e.target.value })}
          />
        </FormItem>
        <FormItem
          label="Phone Number"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Input
            placeholder="+60162223333"
            value={this.state.phoneNumber}
            onChange={e => this.setState({ phoneNumber: e.target.value })}
          />
        </FormItem>
        <FormItem
          label="Role:"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Select
            placeholder="Select a option and change input text above"
            onChange={value => this.setState({ role: value })}
          >
            {CREATE_ROLE_TYPE.map(roleType => {
              return (
                <Select.Option key={roleType.value} value={roleType.value}>
                  {roleType.label}
                </Select.Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem
          label="Sponsor"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Input
            disabled
            placeholder="Sponsor"
            value={currentUser.displayName}
          />
        </FormItem>

        <div style={{ position: "absolute", bottom: 20, right: 25 }}>
          <Button
            type="danger"
            className="login-form-button"
            onClick={this.props.onCancel}
          >
            Cancel
          </Button>

          <PrimaryButton onClick={this.submitForm}>Add New</PrimaryButton>
        </div>
      </Form>
    );
  }
}

class AddNewCustomerPage extends React.Component {
  submitForm = data => {
    const loadingIndicator = message.loading("Creating account...");

    const { email, password, accountType, phoneNumber, displayName } = data;
    createAccount(
      email,
      password,
      accountType,
      phoneNumber,
      displayName,
      this.props.user.user.hierarchy,
      this.props.user.user.hierarchy,
      result => {
        loadingIndicator();
        if (result.type === "success") {
          message.success("Account created");
          this.navigateBack();
        } else {
          message.error(JSON.stringify(result.error));
        }
      }
    );
  };

  navigateBack = () => {
    this.props.dispatch(goBackToPrev());
  };

  render() {
    return (
      <div className="page-container">
        <div
          className="page-header"
          style={{
            backgroundColor: this.props.style.pageHeaderColor,
            color: this.props.style.textColor
          }}
        >
          <div className="page-header-contents">
            <button className="transparent-button" onClick={this.navigateBack}>
              <Icon type="left" />
            </button>
            <div>Customer</div>
          </div>
        </div>

        <div
          className="common-header"
          style={{
            backgroundColor: this.props.style.tableHeaderColor,
            color: this.props.style.textColor
          }}
        >
          Add New Account
        </div>

        <div style={{ padding: "30px 20px 0px 20px" }}>
          <EditEmailForm
            {...this.props}
            onConfirm={this.submitForm}
            onCancel={this.navigateBack}
          />
        </div>
      </div>
    );
  }
}

export default connect(appStore => appStore)(AddNewCustomerPage);

