import { combineReducers } from "redux";
import { mapControl } from "./mapControl";
import { style } from "./style";
import { request } from "./request";
import { notification } from "./notification";
import { user } from './user';

export const appStore = combineReducers({
    request,
    mapControl,
    style,
    notification,
    user
});
