import { ROLE_TYPE } from "../../constants";

/**
 * Gets the user role of the current user
 * @param {string} currentLevel Hierarchy string of the user
 */
export const getCurrentUserRole = (currentLevel = "") => {
  const hierarchyArray = currentLevel.split("#");
  const currentHierarchy = hierarchyArray[hierarchyArray.length - 1];

  if (!currentHierarchy) return ROLE_TYPE.UNKNOWN;

  const roleLetter = currentHierarchy[0];

  switch (roleLetter) {
    case "s":
      return ROLE_TYPE.BUSINESS_ADMIN;
    case "d":
      return ROLE_TYPE.DISTRIBUTOR;
    case "f":
      return ROLE_TYPE.FLEET_OWNER;
    case "m":
      return ROLE_TYPE.FLEET_MANAGER;
    case "o":
      return ROLE_TYPE.OPERATOR;
    default:
      return ROLE_TYPE.UNKNOWN;
  }
};

/**
 * Searches for current user in hierarchy array
 * @param {string} currentLevel Hierarchy string of the current user
 * @param {Array} hierarchyArray Hierarchy array from the server
 */
export const getCurrentUserFromHierarchy = (currentLevel, hierarchyArray) => {
  let user = null;

  hierarchyArray.map(hierarchy => {
    if (hierarchy.hierarchy === currentLevel) user = hierarchy;
  });

  return user;
};

/**
 * Gets the user of the next level
 * @param {string} currentLevel Hierarchy string of the current user
 * @param {Array} hierarchyArray Hoerarchy array from the server
 */
export const getNextLevelUser = (currentLevel, hierarchyArray) => {
  let allBelow = [];

  hierarchyArray.map(hierarchy => {
    if (
      hierarchy.hierarchy.includes(currentLevel) &&
      currentLevel !== hierarchy.hierarchy
    ) {
      allBelow.push(hierarchy);
    }
  });

  const currentLevelHierarchy = currentLevel.split("#").length;

  let belowOneLevel = [];

  allBelow.map(hierarchy => {
    const hierarchyLength = hierarchy.hierarchy.split("#").length;
    if (hierarchyLength === currentLevelHierarchy + 1) {
      belowOneLevel.push(hierarchy);
    }
  });

  return belowOneLevel;
};
