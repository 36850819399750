import React from "react";
import { Row, Spin } from "antd";

/**
 * Indicates a certain part of the app is loading
 */
export const LoadingIndicator = () => {
    return (
        <Row type="flex" justify="center" style={{ margin: 10 }}>
            <Spin tip="Please wait while we're pulling some data..." />
        </Row>
    );
};
