export const DEV_MODE = "DEV_MODE";
export const TEST_MODE = "TEST_MODE";
export const PRODUCTION_MODE = "PRODUCTION_MODE";

//Replace firebaseConfig manually at /public/firebase-messaging-sw.js as well
let mode = TEST_MODE; //Switch

let config = {};

config.version = "0.1.0";

if (mode === DEV_MODE) {
    config.appMode = DEV_MODE; //For function disabling in non-dev mode

    //API Endpoint Address Configuration
    config.endPointIdentifier = "f382sc4v9d";
    config.betaEndPointIdentifier = "kegts10no8";
    config.endPointPath = "s5dev";
    config.betaEndPointPath = "s3dev";

    //Google Maps API Configuration
    config.googleMapsApiKey = "AIzaSyDkNxtMNYlkCBArfv8KF7zYPAvROOr7XS0";

    //Firebase Project Configuration
    config.firebaseConfig = {
        apiKey: "AIzaSyC9x62lCXEIoQ_KFUiI31a-3SlicVT0V7g",
        authDomain: "gpswebdemo.firebaseapp.com",
        databaseURL: "https://gpswebdemo.firebaseio.com",
        projectId: "gpswebdemo",
        storageBucket: "gpswebdemo.appspot.com",
        messagingSenderId: "23125386658",
    };

    //Encryption configuration for sending user credentials
    config.encryption = {
        key: "rsQVROjGennWoq27x3iawdXVDklPRibTWJuchrCdhWNfvlzodbr1zAWYVy6013xS",
        algorithm: "aes-256-cbc",
    };

    //Map address request service target
     config.addressMode = "google"; //"google" or "server"
    //config.addressMode = "loli"; //"google" or "server"

    //Database name
    config.dbPath = {
        STATUS_RECORD: "DEP-StatusRecord",
        FLEET_RECORD: "DEP-FleetRecord",
        DEVICE: "DEP-DeviceTable",
        POI: "DEP-PointofInterest",
        GEOFENCE: "DEP-Polygon",
    };

    //Versioning
    config.version = config.version + "DEV";
} else if (mode === TEST_MODE) {
    config.appMode = TEST_MODE; //For function disabling in non-dev mode

    //API Endpoint Address Configuration
    config.endPointIdentifier = "f382sc4v9d";
    config.betaEndPointIdentifier = "kegts10no8";
    config.endPointPath = "s5dev";
    config.betaEndPointPath = "s3dev";

    //Google Maps API Configuration
    // Using iqolapp@gmail.com
    config.googleMapsApiKey = "AIzaSyDkNxtMNYlkCBArfv8KF7zYPAvROOr7XS0";

    //Firebase Project Configuration
    config.firebaseConfig = {
        apiKey: "AIzaSyC9x62lCXEIoQ_KFUiI31a-3SlicVT0V7g",
        authDomain: "gpswebdemo.firebaseapp.com",
        databaseURL: "https://gpswebdemo.firebaseio.com",
        projectId: "gpswebdemo",
        storageBucket: "gpswebdemo.appspot.com",
        messagingSenderId: "23125386658",
    };

    //Encryption configuration for sending user credentials
    config.encryption = {
        key: "rsQVROjGennWoq27x3iawdXVDklPRibTWJuchrCdhWNfvlzodbr1zAWYVy6013xS",
        algorithm: "aes-256-cbc",
    };

    //Map address request service target
    config.addressMode = "google"; //"google" or "server"
    // config.addressMode = "loli"; //"google" or "server"

    //Database name
    config.dbPath = {
        STATUS_RECORD: "DEP-StatusRecord",
        FLEET_RECORD: "DEP-FleetRecord",
        DEVICE: "DEP-DeviceTable",
        POI: "DEP-PointofInterest",
        GEOFENCE: "DEP-Polygon",
    };

    //Versioning
    config.version = config.version + "ALPHA";
} else if (mode === PRODUCTION_MODE) {
    config.appMode = PRODUCTION_MODE;
    config.baseAddress = "";
    config.googleMapsApiKey = "AIzaSyDkNxtMNYlkCBArfv8KF7zYPAvROOr7XS0";
    config.firebaseConfig = {
        messagingSenderId: "",
    };
    config.encryption = {
        key: "rsQVROjGennWoq27x3iawdXVDklPRibTWJuchrCdhWNfvlzodbr1zAWYVy6013xS",
        algorithm: "aes-256-cbc",
    };
    config.addressMode = "google";
}

config.baseAddress = `https://${config.endPointIdentifier}.execute-api.ap-southeast-1.amazonaws.com/${config.endPointPath}/`;
config.betaBaseAddress = `https://${config.betaEndPointIdentifier}.execute-api.ap-southeast-1.amazonaws.com/${config.betaEndPointPath}/`;
config.googleApiURL = `https://maps.googleapis.com/maps/api/js?v=3.35&key=${config.googleMapsApiKey}&libraries=geometry,drawing,places`;

export default config;
