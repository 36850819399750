import React, { Component } from "react";
import "./App.css";
import { connect } from "react-redux";
import {
    moveToMenuSelection,
    moveToPage,
} from "./navigation/navigationService";
import { Menu, Dropdown, Icon } from "antd";
import config from "./config";
import BrowserNotifications from "./components/browser-notifications/BrowserNotifications";
import { AppRoutes } from "./navigation/AppRoutes";
import { MainRoutes } from "./navigation/MainRoutes";
import { initializePush } from "./services/push-notification/initialize";
import { dispatchWithUserData } from "./services/redux/actions/user";
import { toggleSidebar } from "./services/redux/actions/style";
import { ROLE_TYPE, FEATURE } from "./constants";
import { buildAbility } from "./services/auth/accessRight";
import { isIOS } from "react-device-detect";
import Layout from "./components/Layout";

const FULL_SIDEBAR_WIDTH = "400px";
class App extends Component {
    state = {
        currentStatusList: [],
        _loaded: false,
        roleType: ROLE_TYPE.UNKNOWN,
        isSidebarCollapsed: false,
    };

    menu = button => (
        <Menu>
            {button.list.map((menuItem, index) => (
                <Menu.Item key={index}>
                    <a
                        onClick={e =>
                            this.props.dispatch(moveToMenuSelection(menuItem))
                        }
                        rel="noopener noreferrer"
                        id={menuItem}
                    >
                        {menuItem}
                    </a>
                </Menu.Item>
            ))}
        </Menu>
    );

    componentDidMount() {
        console.log(`App running in: ${config.appMode}`);

        // this.props.dispatch(dispatchWithUserData());

        // if (!isIOS) {
        //     initializePush();
        // }
    }

    render() {
        const { isSidebarCollapsed } = this.props.style;
        let { user } = this.props.user;

        return (
            <Layout user={user || {}}>
                <div className="app-frame">
                    {/* <BrowserNotifications /> */}
                    {!isSidebarCollapsed && (
                        <div
                            className="sidebar card animate-left"
                            style={{
                                width: FULL_SIDEBAR_WIDTH,
                            }}
                            id="SideBar"
                        >
                            <div className="sidebar-content-container">
                                <AppRoutes />
                            </div>
                        </div>
                    )}

                    <div className="app-content">
                        <MainRoutes {...this.props} />
                    </div>
                </div>
            </Layout>
        );
    }
}

export default connect(appStore => appStore)(App);
