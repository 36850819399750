import React from "react";
import PropTypes from "prop-types";
import SidebarItems from "./SidebarItems";
import Logo from "./Logo";
import { Layout } from "antd";
import "./index.css";

const { Sider } = Layout;

const Sidebar = props => {
  return (
    <Sider
      collapsed={props.collapsed}
      style={{
        height: "100vh",
        overflowY: "hidden",
        backgroundColor: props.themeColor
      }}
    >
      <Logo onClick={props.onClick} selectedKeys={props.selectedKeys} />
      <SidebarItems
        onClick={props.onClick}
        theme={props.theme}
        collapsed={props.collapsed}
        items={props.items}
        selectedKeys={props.selectedKeys}
      />
    </Sider>
  );
};

Sidebar.propTypes = {
  collapsed: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  onClick: PropTypes.func,
  selectedKeys: PropTypes.string,
  themeColor: PropTypes.string
};

Sidebar.defaultProps = {
  collapsed: true,
  items: [],
  onClick: () => {}
};

export default Sidebar;
