import React, { Component } from "react";
import PropTypes from "prop-types";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import { Marker } from "react-google-maps";

export default class MarkerWithInfoBox extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        coordinate: PropTypes.shape({
            lat: PropTypes.number.isRequired,
            lng: PropTypes.number.isRequired,
        }).isRequired,
        icon: PropTypes.any,
        onClick: PropTypes.func,
        infoBoxBackgroundColor: PropTypes.string,
        infoBoxFontColor: PropTypes.string,
        infoBoxContent: PropTypes.string,
    };

    state = {
        showInfoBox: false,
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.showInfoBox !== prevProps.showInfoBox) {
            this.setState({ showInfoBox: this.props.showInfoBox });
        }
    }

    handleClick = () => {
        this.setState({ showInfoBox: !this.state.showInfoBox });
        this.props.onClick();
    };

    render() {
        return (
            <Marker
                key={this.props.id}
                position={this.props.coordinate}
                icon={this.props.icon}
                onClick={this.handleClick}
            >
                {this.state.showInfoBox && (
                    <InfoBox>
                        <div
                            style={{
                                background: this.props.infoBoxBackgroundColor,
                                padding: "12px",
                                borderRadius: "0 15px 15px 15px",
                            }}
                        >
                            <div
                                style={{
                                    maxWidth: "250px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    color: this.props.infoBoxFontColor,
                                }}
                            >
                                {this.props.infoBoxContent}
                            </div>
                        </div>
                    </InfoBox>
                )}
            </Marker>
        );
    }
}
