import * as ActionTypes from "../action-types/mapControl";
import { requestAddress } from "../../geo/addressRequest";

export const switchToLocateMode = (
    markerList = [],
    poiList = [],
    shapeList = []
) => {
    if (!Array.isArray(markerList)) markerList = [];
    if (!Array.isArray(poiList)) poiList = [];
    if (!Array.isArray(shapeList)) shapeList = [];
    console.log("markerList", markerList);
    console.log("poiList", poiList);
    console.log("shapeList", shapeList);
    markerList = markerList
        .filter(marker => {
            // const markerFields = [
            // "fleet",
            // "name",
            // "coordinates",
            // "range",
            // "condition",
            // ];
            const markerFields = [
                "id",
                "vehicle",
                "address",
                "coordinate",
                "status",
                "speed",
                "direction",
            ];
            let statusCheck = true;
            markerFields.map(field => {
                if (marker[field] === undefined) statusCheck = false;
            });

            return statusCheck;
        })
        .map(filteredMarkerList => ({
            ...filteredMarkerList,
            showInfoBox: false,
        }));
    poiList = poiList.filter(
        // poi => !poi["archived"] && poi["id"] && poi["POI"] && poi["poiName"]
        poi => !poi["archived"] && poi["coordinates"] && poi["name"]
    );

    shapeList = shapeList.filter(
        shape =>
            !shape["archived"] &&
            shape["id"] &&
            shape["coordinateList"] &&
            shape["polygonName"]
    );

    return {
        type: ActionTypes.SWITCH_TO_LOCATE_MODE,
        markerList,
        poiList,
        shapeList,
    };
};

export const switchToPlaybackMode = (pathCoordinates = [], markerList = []) => {
    if (!Array.isArray(pathCoordinates)) pathCoordinates = [];

    pathCoordinates = pathCoordinates.filter(coordinate => {
        if (coordinate.lat !== 0 && !coordinate.lat) return false;
        if (coordinate.lng !== 0 && !coordinate.lng) return false;

        return true;
    });

    return {
        type: ActionTypes.SWITCH_TO_PLAYBACK_MODE,
        pathCoordinates,
        markerList,
    };
};

export const switchToDrawPolygonMode = () => {
    return {
        type: ActionTypes.SWITCH_TO_DRAW_POLYGON_MODE,
    };
};

export const switchToDrawPointMode = () => {
    return {
        type: ActionTypes.SWITCH_TO_DRAW_POINT_MODE,
    };
};

export const passPointCoordinate = (coordinate = {}) => dispatch => {
    if (coordinate.lat !== 0 && !coordinate.lat) coordinate = {};
    if (coordinate.lng !== 0 && !coordinate.lng) coordinate = {};

    dispatch(dispatchPointCoordinate(coordinate, "Loading address..."));

    requestAddress(coordinate).then(address => {
        dispatch(dispatchPointCoordinate(coordinate, address));
    });
};

const dispatchPointCoordinate = (coordinate, address) => {
    return {
        type: ActionTypes.PASS_POINT_COORDINATE,
        coordinate,
        address,
    };
};

export const passPolygonCoordinateList = (coordinateList = []) => {
    if (!Array.isArray(coordinateList)) coordinateList = [];

    coordinateList = coordinateList.filter(coordinate => {
        if (coordinate.lat !== 0 && !coordinate.lat) return false;
        if (coordinate.lng !== 0 && !coordinate.lng) return false;

        return true;
    });

    return {
        type: ActionTypes.PASS_POLYGON_COORDINATE_LIST,
        coordinateList,
    };
};

export const togglePlaybackInfoBox = markerId => {
    return {
        type: ActionTypes.TOGGLE_PLAYBACK_INFO_BOX,
        markerId,
    };
};

export const openPlaybackInfoBox = markerId => {
    return {
        type: ActionTypes.OPEN_PLAYBACK_INFO_BOX,
        markerId,
    };
};
