/**
 * Stores all constants for GoogleMaps component
 */
export const LOCATE_MODE = "locate";
export const PLAYBACK_MODE = "playback";
export const DRAW_MODE = "draw";
export const MARKER = "marker";
export const POLYGON = "polygon";

export const DISCONNECTED = "DISCONNECTED";
export const OFFLINE = "OFFLINE";
export const INACTIVE = "INACTIVE";
export const PARKING = "PARKING";
//export const STOP = 'STOP';
// export const STOP = "ONLINE";
export const STOP = "ENGINE STARTED";
export const IDLE = "IDLE";
export const MOVING = "MOVING";
// export const MOVING = "TRANSIT";

export const DISCONNECTED_COLOR = "#ff2a2a"; //red
export const OFFLINE_COLOR = "#333333"; //black
export const INACTIVE_COLOR = "#ff7f2a"; //orange
export const PARKING_COLOR = "#D4FF2A"; //yellow
export const STOP_COLOR = "#2A2AFF"; //blue
export const IDLE_COLOR = "#7F2AFF"; //purple
export const MOVING_COLOR = "#37c837"; //green

export const ZOOM_IN_MULTIPLIER = 12;
export const ZOOM_OUT_MULTIPLIER = 12;
export const ZOOM_TO_SHAPE_MULTIPLIER = 10;
export const DEFAULT_ZOOM_MULTIPLIER = 6;

export const DEFAULT_PLAYBACK_SPEED = 1;
export const PLAYBACK_INTERVAL = 35;

export const DEFAULT_MAP_CENTER = {
  lat: 2.549001,
  lng: 110
};
