import config from "../../config";
import { DEFAULT_MAP_CENTER } from "../../components/google-maps/MapConstants";

/**
 * Requests address for a coordinate, returns back a promise
 * @param {object} coordinate Coordinate to request
 */
export const requestAddress = coordinate => {
    const { lat, lng } = coordinate;
    let requestLink, option;

    if ((!lat && lat !== 0) || (!lng && lng !== 0))
        return new Promise(resolve => {
            resolve("Address not found.");
        });

    if (config.addressMode === "google") {
        // TODO : Temporary disable geolocation, fix later
        requestLink = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${config.googleMapsApiKey}`;

        return fetch(requestLink)
            .then(response => {
                if (response.status === 200) return response.json();
                else throw new Error("Fail to get address");
            })
            .then(result =>
                result.results.length > 0
                    ? result.results[0].formatted_address
                    : "Address not found."
            )
            .catch(() => "Address not found.");
    } else if (config.addressMode === "google") {
        requestLink = `https://wb9sc76cz5.execute-api.ap-southeast-1.amazonaws.com/s1/reversegeocode`;
        option = {
            method: "POST",
            body: JSON.stringify({
                queryStringParameters: `${lat},${lng}`,
            }),
        };

        return fetch(requestLink, option)
            .then(response => response.json())
            .then(resultStatus => {
                if (resultStatus.statusCode === 200) return resultStatus;
                else throw new Error("Fail to get address");
            })
            .then(result =>
                JSON.parse(result.body).length > 0
                    ? JSON.parse(result.body)[0].address
                    : "Address not found"
            )
            .catch(() => "Address not found");
    }
};

// TODO : temporary request address, to prevent O(n) on google map api which cost alot
export const tempRequestAddress = coordinate => {
    const { lat, lng } = coordinate;
    return new Promise(resolve => {
        resolve(" ");
    });
}
