import * as ActionTypes from "../action-types/user";
import * as RequestActionTypes from "../action-types/request";

export const user = (state = {}, action) => {
  let newState = state;
  switch (action.type) {
    case ActionTypes.SAVE_USER_INFO: {
      let user = action.user;

      //if (!user) return state;

      if (!user.viewableDevice) user.viewableDevice = [];
      if (!user.viewablePoi) user.viewablePoi = [];
      if (!user.viewablePolygon) user.viewablePolygon = [];

      return Object.assign({}, state, { user });
    }
    case ActionTypes.DELETE_USER_INFO: {
      return Object.assign({}, state, { user: null });
    }
    /** POI CREATION CASES */
    case RequestActionTypes.CREATE_POI_SUCCESS: {
      newState = {
        ...state,
        user: {
          ...state.user,
          viewablePoi: [...state.user.viewablePoi, action.result.id]
        }
      };

      return newState;
    }
    /** GEOFENCE CREATION CASES */
    case RequestActionTypes.CREATE_GEOFENCE_SUCCESS: {
      newState = {
        ...state,
        user: {
          ...state.user,
          viewablePolygon: [
            ...state.user.viewablePolygon,
            ...action.result.map(geofence => geofence.id)
          ] //n/b: geofence data is an array
        }
      };

      return newState;
    }

    default: {
      return state;
    }
  }
};
