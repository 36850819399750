import firebase from "firebase/app";
import "firebase/auth";
import crypto from "crypto";
import config from "../../config";
import { postData } from "../rest/RestRequest";
import { API_RESOURCES } from "../../constants";

/**
 * Creates a user instance at the server and Firebase
 * @param {string} email Email address
 * @param {string} password User password
 * @param {string} role User role
 * @param {string} phoneNumber User phone number
 * @param {function} callback Callback when create user is completed
 */
export const createAccount = (
  email,
  password,
  role,
  phoneNumber,
  displayName,
  userHierarchy,
  targetHierarchy,
  callback
) => {
  const mailCipher = crypto.createCipher(
    config.encryption.algorithm,
    config.encryption.key
  );

  const passCipher = crypto.createCipher(
    config.encryption.algorithm,
    config.encryption.key
  );

  let encryptedEmail = mailCipher.update(email, "utf8", "hex");
  encryptedEmail += mailCipher.final("hex");

  let encryptedPassword = passCipher.update(password, "utf8", "hex");
  encryptedPassword += passCipher.final("hex");

  postData(API_RESOURCES.SIGN_UP, {
    email: encryptedEmail,
    password: encryptedPassword,
    phoneNumber,
    user: { hierarchy: userHierarchy },
    newUser: {
      hierarchy: targetHierarchy,
      displayName: displayName,
      accountType: role
    }
  })
    .then(response => {
      console.log("response :", response);
      if (response.status >= 200 && response.status < 300)
        return response.json();
      else throw new Error(`${response.status} ${response.statusText}`);
    })
    .then(result => {
      console.log("result :", result);
      callback({
        type: "success",
        result
      });
    })
    .catch(error =>
      callback({
        type: "fail",
        error
      })
    );
};

/**
 * Signs in to Firebase using email and password
 * @param {string} email Email address used to sign in
 * @param {string} password Password to login
 * @param {function} callback Callback function when sign in action is completed
 */
export const signInAccount = (email, password, callback) => {
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(result => {
      callback({
        type: "success",
        result
      });
    })
    .catch(error => {
      callback({
        type: "fail",
        error
      });
    });
};

/**
 * Checks if user is logged in
 */
export const checkLoginStatus = () => {
  console.log("firebase.auth().currentUser :", firebase.auth().currentUser);

  return firebase.auth().currentUser ? true : false;
};

/**
 * Signs user out from the account
 * @param {function} callback Callback function when sign out action is performed
 */
export const signOutAccount = (callback = () => {}) => {
  firebase
    .auth()
    .signOut()
    .then(result => {
      callback({
        type: "success",
        result
      });
    })
    .catch(error => {
      callback({
        type: "fail",
        error
      });
    });
};

/**
 * Retrieve ID token from Firebase
 * @param {function} callback Callback function when token retrieve action is done
 */
export const retrieveIdToken = () => {
  if (!firebase.auth().currentUser)
    throw new Error("No available signed in user");

  return firebase.auth().currentUser.getIdToken();
};

/**
 *
 * @param {string} password User input password
 * @param {Function} callback Callback function when re-auth is done
 */
export const reauthenticateUser = (password, callback) => {
  firebase
    .auth()
    .currentUser.reauthenticateAndRetrieveDataWithCredential(
      firebase.auth.EmailAuthProvider.credential(
        firebase.auth().currentUser.email,
        password
      )
    )
    .then(result =>
      callback({
        type: "success",
        result
      })
    )
    .catch(error => {
      let errorMessage;

      switch (error.code) {
        case "auth/user-mismatch":
          errorMessage = "Wrong credentials provided";
          break;
        case "auth/user-not-found":
          errorMessage = "Invalid user";
          break;
        case "auth/invalid-credential":
          errorMessage = "Provider's credential is not valid";
          break;
        case "auth/invalid-email":
          errorMessage = "Email is invalid";
          break;
        case "auth/wrong-password":
          errorMessage = "Wrong password, please try again";
          break;
        case "auth/invalid-verification-code":
          errorMessage =
            "Verification code is invalid, please resend another one";
          break;
        case "auth/invalid-verification-id":
          errorMessage = "Invalid verification id";
          break;
        default:
          errorMessage =
            "Something's broken, please try again or contact admin";
          break;
      }

      callback({
        type: "fail",
        error: errorMessage
      });
    });
};

/**
 *
 * @param {string} newEmail New email address
 * @param {string} password Password for authenticate purpose
 * @param {Function} callback Callback function when operation is done
 */
export const reauthAndUpdateEmail = (newEmail, password, callback) => {
  reauthenticateUser(password, result => {
    if (result.type === "success") {
      updateEmail(newEmail, callback);
    } else {
      callback({
        type: "fail",
        error: result.error
      });
    }
  });
};

export const reauthAndUpdatePassword = (newPassword, password, callback) => {
  reauthenticateUser(password, result => {
    if (result.type === "success") {
      updatePassword(newPassword, callback);
    } else {
      callback({
        type: "fail",
        error: result.error
      });
    }
  });
};

//TODO: reauth and update phone number
// export const reauthAndUpdatePhone = (newPhone, password, callback) => {
//     reauthenticateUser(password, () => {
//
//     })
// }

/**
 * Updates email address of Firebase account
 * @param {string} newEmail New email address
 * @param {function} callback Callback function when email is updated
 */
const updateEmail = (newEmail, callback) => {
  if (!firebase.auth().currentUser)
    throw new Error("No available signed in user");

  firebase
    .auth()
    .currentUser.updateEmail(newEmail)
    .then(result => {
      callback({
        type: "success",
        result
      });
    })
    .catch(error => {
      callback({
        type: "fail",
        error: error.code
      });
    });
};

/**
 * Updates password of Firebase account
 * @param {string} newPassword New password
 * @param {function} callback Callback function when password is updated
 */
const updatePassword = (newPassword, callback) => {
  if (!firebase.auth().currentUser)
    throw new Error("No available signed in user");

  firebase
    .auth()
    .currentUser.updatePassword(newPassword)
    .then(result => {
      callback({
        type: "success",
        result
      });
    })
    .catch(error => {
      callback({
        type: "fail",
        error: error.code
      });
    });
};

//TODO: Update phone number
// const updatePhone = (newPhone, callback) => {
//     if (!firebase.auth().currentUser)
//         throw new Error("No available signed in user");

//     firebase
//         .auth()
//         .currentUser.updatePhoneNumber(newPhone)
// }

/**
 * Updates profile of Firebase account
 * @param {string} newEmail New profile name
 * @param {function} callback Callback function when email is updated
 */
export const updateProfileName = (newProfileName, callback) => {
  if (!firebase.auth().currentUser)
    throw new Error("No available signed in user");

  firebase
    .auth()
    .currentUser.updateProfile({
      displayName: newProfileName,
      photoURL: null
    })
    .then(result => {
      callback({
        type: "success",
        result
      });
    })
    .catch(error => {
      callback({
        type: "fail",
        error: error.code
      });
    });
};

/**
 * Returns current user information
 */
export const retrieveUserData = () => {
  if (!firebase.auth().currentUser)
    throw new Error("No available signed in user");

  const user = firebase.auth().currentUser;

  return {
    displayName: user.displayName,
    uid: user.uid,
    email: user.email,
    phoneNumber: user.phoneNumber
  };
};
