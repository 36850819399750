import React, { Component } from "react";
import PropTypes from "prop-types";
import Layout from "./Layout";
import { moveToPage } from "../../navigation/navigationService";
import { connect } from "react-redux";
import { FEATURE } from "../../constants";
import { withRouter } from "react-router";
import { buildAbility } from "../../services/auth/accessRight";

class LayoutContainer extends Component {
    static propTypes = {
        children: PropTypes.element,
    };

    navigateTo = pageKey => this.props.dispatch(moveToPage(pageKey));

    sidebarItems = [
        {
            key: `/${FEATURE.DISTRIBUTOR_DASHBOARD}`,
            icon: "tool",
            label: "Device Management",
        },
        {
            key: `/${FEATURE.CUSTOMER}`,
            icon: "team",
            label: "Customer",
        },
        {
            key: `/${FEATURE.OPERATOR_ACCOUNT}`,
            icon: "solution",
            label: "Operator Account",
        },
        {
            key: `/${FEATURE.ALERTS}`,
            icon: "exclamation-circle",
            label: "Alerts",
        },
        {
            key: `/${FEATURE.REPORT}`,
            icon: "file-text",
            label: "Report",
        },
        {
            key: `/${FEATURE.FLEET_TRACKING}`,
            icon: "fork",
            label: "Fleet Tracking",
        },
        {
            key: `/${FEATURE.SUMMARY}`,
            icon: "stock",
            label: "Daily Summary (For Ebike Only)",
        },
        // {
        //   key: `/${FEATURE.CASES}`,
        //   icon: "switcher",
        //   label: "Cases"
        // },
        {
            key: `/${FEATURE.POI}`,
            icon: "environment-o",
            label: "POI",
        },
        {
            key: `/${FEATURE.GEOFENCE}`,
            icon: "gateway",
            label: "Geofence",
        },
        {
            key: `/${FEATURE.PROFILE}`,
            icon: "user",
            label: "Profile",
        },
        // {
        //   key: `/${FEATURE.PREFERENCES}`,
        //   icon: "setting",
        //   label: "Preferences"
        // },
        // {
        //   key: `/${FEATURE.ABOUT}`,
        //   icon: "info-circle",
        //   label: "About"
        // }
    ];

    render() {
        const { user, location } = this.props;

        let items = this.sidebarItems.filter(listItem =>
            buildAbility(user.accountType).can("read", listItem.key.slice(1))
        );

        return (
            <Layout
                sidebarItems={items}
                onClickSidebarItem={sidebarItem =>
                    this.navigateTo(sidebarItem.key || "/")
                }
                // selectedKeys={[location.pathname]}
                selectedKeys={location.pathname}
                themeColor={this.props.themeColor}
            >
                {this.props.children}
            </Layout>
        );
    }
}

const mapStateToProps = state => ({
    themeColor: state.style.sidebarColor,
});

export default connect(mapStateToProps)(withRouter(LayoutContainer));
