import React, { Component } from "react";
import PropTypes from "prop-types";
import { GoogleMapsComponent } from "./GoogleMapsComponent";
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";
import {
    DEFAULT_ZOOM_MULTIPLIER,
    DEFAULT_MAP_CENTER,
    MARKER,
    POLYGON
} from "./MapConstants";
import { Marker } from "react-google-maps";

/*
export const LOCATE_MODE = 'locate';
export const PLAYBACK_MODE = 'playback';
export const DRAW_MODE = 'draw';
export const MARKER = 'marker';
export const POLYGON = 'polygon';

export const DISCONNECTED = 'DISCONNECTED';
export const OFFLINE = 'OFFLINE';
export const INACTIVE = 'INACTIVE';
export const PARKING = 'PARKING';
export const STOP = 'STOP';
export const IDLE = 'IDLE';
export const MOVING = 'MOVING';

export const DISCONNECTED_COLOR = 'red';
export const OFFLINE_COLOR = 'black';
export const INACTIVE_COLOR = 'orange';
export const PARKING_COLOR = 'yellow';
export const STOP_COLOR = 'blue';
export const IDLE_COLOR = 'purple';
export const MOVING_COLOR = 'green';

export const ZOOM_IN_MULTIPLIER = 15;
export const ZOOM_OUT_MULTIPLIER = 12;
export const ZOOM_TO_SHAPE_MULTIPLIER = 10;
export const DEFAULT_ZOOM_MULTIPLIER = 6;

export const DEFAULT_PLAYBACK_SPEED = 1;
export const PLAYBACK_INTERVAL = 50;


export const DEFAULT_MAP_CENTER = {
		lat: 2.549001,
		lng: 110
};
*/

/**
 * Google Maps designed for user drawing
 * @version 1.0.0
 * @see Check https://github.com/tomchentw/react-google-maps for the library used in this component
 * @see Requires `<script src="https://maps.googleapis.com/maps/api/js?key=API_KEY"></script>` in index.html
 * @requires GoogleMapsComponent
 * @requires MapConstants
 */
class GoogleMapsDraw extends Component {
    static propTypes = {
        /** Drawing mode, either plotting markers or draw polygon */
        drawType: PropTypes.oneOf(["marker", "polygon"]).isRequired,
        /** Event when a draw action is completed */
        onDrawComplete: PropTypes.func.isRequired,
        /** Zoom level of the drawing map */
        zoom: PropTypes.number,
        /** Map center of the drawing map */
        center: PropTypes.shape({
            lat: PropTypes.number.isRequired,
            lng: PropTypes.number.isRequired
        }),
        /** Activation of showing traffic condition */
        showTraffic: PropTypes.bool
    };

    static defaultProps = {
        zoom: DEFAULT_ZOOM_MULTIPLIER,
        center: DEFAULT_MAP_CENTER,
        showTraffic: false
    };

    state = {
        shapeCoordinates: [],
        mapLoaded: false
    };

    drawPolygonComplete = event => {
        let coordinatesFunctions = event.latLngs.j[0].j;
        let coordinates = coordinatesFunctions.map(
            (coordinateFunction, index) => {
                return {
                    index: index,
                    lat: coordinateFunction.lat(),
                    lng: coordinateFunction.lng()
                };
            }
        );

        this.props.onDrawComplete(coordinates);
    };

    plotMarker = event => {
        let coordinate = {
            lat: event.position.lat(),
            lng: event.position.lng()
        };

        this.props.onDrawComplete(coordinate);
    };

    mapLoadComplete = () => {
        this.setState({ mapLoaded: true });
    };

    render() {
        return this.props.drawType === "polygon" ? (
            <GoogleMapsComponent
                zoom={this.props.zoom}
                center={this.props.center}
                showTraffic={this.props.showTraffic}
                onMapLoad={this.mapLoadComplete}
                googleMapURL={this.props.googleMapURL}
            >
                {this.state.mapLoaded && (
                    <DrawingManager
                        defaultDrawingMode={POLYGON}
                        defaultOptions={{
                            drawingControl: false,
                            drawingControlOptions: {
                                position:
                                    window.google.maps.ControlPosition
                                        .TOP_CENTER,
                                drawingModes: [POLYGON]
                            }
                        }}
                        onPolygonComplete={this.drawPolygonComplete}
                    />
                )}
            </GoogleMapsComponent>
        ) : (
            //TODO: Try to make new logic without window.google.maps.drawing.OverlayType.MARKER, might be removed anytime by Google
            <GoogleMapsComponent
                zoom={this.props.zoom}
                center={this.props.center}
                showTraffic={this.props.showTraffic}
                onMapLoad={this.mapLoadComplete}
                googleMapURL={this.props.googleMapURL}
            >
                <Marker position={this.props.drawModeMarkerPosition}/>
                {this.state.mapLoaded && (
                    <DrawingManager
                        defaultDrawingMode={MARKER}
                        defaultOptions={{
                            markerOptions: {
                                visible: false
                            },
                            drawingControl: false,
                            drawingControlOptions: {
                                position:
                                    window.google.maps.ControlPosition
                                        .TOP_CENTER,
                                drawingModes: [MARKER]
                            }
                        }}
                        onMarkerComplete={this.plotMarker}
                    />
                )}
            </GoogleMapsComponent>
        );
    }
}

export default GoogleMapsDraw;
