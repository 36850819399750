import { FaShippingFast, FaVectorSquare } from "react-icons/fa";
import {
  MdLocationOn,
  MdPause,
  MdSignalCellularConnectedNoInternet0Bar,
  MdSignalCellularConnectedNoInternet4Bar
} from "react-icons/md";

export const TYPES = [
  "poiAlert",
  "geofenceAlert",
  "Speeding",
  "Idle",
  "Offline",
  "Online"
];

export const POI = TYPES[0];
export const GEOFENCE = TYPES[1];
export const SPEEDING = TYPES[2];
export const IDLE = TYPES[3];
export const OFFLINE = TYPES[4];
export const ONLINE = TYPES[5];

export const LABELS_BY_TYPE = {
  [POI]: "POI",
  [GEOFENCE]: "Geofence",
  [SPEEDING]: "Speeding",
  [IDLE]: "Idle",
  [OFFLINE]: "Offline",
  [ONLINE]: "Online"
};

export const DROPDOWN_OPTIONS = [
  { label: "All", value: "All" },
  ...TYPES.map(alertType => ({
    label: LABELS_BY_TYPE[alertType],
    value: alertType
  }))
];

export const ICONS_BY_TYPE = {
  [POI]: MdLocationOn,
  [GEOFENCE]: FaVectorSquare,
  [SPEEDING]: FaShippingFast,
  [IDLE]: MdPause,
  [OFFLINE]: MdSignalCellularConnectedNoInternet0Bar,
  [ONLINE]: MdSignalCellularConnectedNoInternet4Bar
};
