import React, { Component } from "react";
import { Row, Alert } from "antd";

/**
 * Indicates no data is available.
 */
export const EmptyIndicator = () => {
    return (
        <Row type="flex" justify="center" style={{ margin: 10 }}>
            <Alert
                message="No data"
                description="Looks like we have nothing to show you here!"
                showIcon
                type="info"
            />
        </Row>
    );
};
