import React from "react";
import { Switch, Route } from "react-router";
import asyncComponent from "./../components/AsyncComponent";
import { MapContainer } from "../components/MapContainer";
import DistributorDashboard from "../pages/DistributorDashboard";
import DistributorEditPage from "../pages/DistributorEditPage";
import DistributorAddNewDevicePage from "../pages/DistributorAddNewDevicePage";
import DashboardMain from "../pages/DashboardMain";
import showSecondarySidebar from "../components/Layout/helpers/showSecondarySidebar";
import AddNewCustomerPage from "../pages/AddNewCustomerPage";

/** wrap with asyncComponent HOC so that the import are async to achieve code splitting */
const AsyncCustomerDetailsPage = asyncComponent(() =>
  import("../pages/CustomerDetailsPage")
);
const AsyncReportPage = asyncComponent(() => import("../pages/ReportPage"));
const AsyncSummaryPage = asyncComponent(() => import("../pages/SummaryPage"));
const AsyncAlertsPage = asyncComponent(() => import("../pages/AlertsPage"));

/** wrap with HOC to show sidebar for all routes that uses this component */
const MainMapWithSecondarySidebar = showSecondarySidebar(true)(MapContainer);

/**
 * Available Routes for the app
 */

export const MainRoutes = props => {
  return (
    <Switch>
      <Route
        exact
        path={[
          "/Fleet Tracking",
          "/Cases",
          "/POI",
          "/Geofence",
          "/POI/*",
          "/Geofence/*",
          "/Operator Account",
          "/Profile",
          "/Preferences",
          "/About"
        ]}
        render={routeProps => (
          <MainMapWithSecondarySidebar {...props} {...routeProps} />
        )}
      />
      <Route
        exact
        path="/"
        render={routeProps => <DashboardMain {...props} {...routeProps} />}
      />
      <Route
        exact
        path={"/Distributor Dashboard"}
        render={routeProps => (
          <DistributorDashboard {...props} {...routeProps} />
        )}
      />
      <Route
        exact
        path="/Distributor Dashboard/AddNewDevice"
        render={routeProps => (
          <DistributorAddNewDevicePage {...props} {...routeProps} />
        )}
      />
      <Route
        exact
        path="/Distributor Dashboard/Edit"
        render={routeProps => (
          <DistributorEditPage {...props} {...routeProps} />
        )}
      />
      <Route
        exact
        path="/Customer"
        component={AsyncCustomerDetailsPage}
      />
      <Route
        exact
        path="/Customer/AddNew"
        render={routeProps => (
          <AddNewCustomerPage {...props} {...routeProps} />
        )}
      />
      <Route exact path="/Report" component={AsyncReportPage} />
      <Route exact path="/Summary" component={AsyncSummaryPage} />
      <Route exact path="/Alerts" component={AsyncAlertsPage} />
    </Switch>
  );
};
