import React from "react";
import "../App.css";
import { connect } from "react-redux";
import { Form, Icon, Input, Button, Select, message, DatePicker } from "antd";
import { moveToPage, goBackToPrev } from "../navigation/navigationService";
import { retrieveUserData } from "../services/auth/authService";
import { validateImei } from "../services/validator/validateImei";
import { validatePhoneInput } from "../services/validator/validatePhone";
import { dispatchWithUserData } from "../services/redux/actions/user";
import { requestData, createDataList } from "../services/redux/actions/request";
import {
  API_RESOURCES,
  DEVICE_TYPE,
  SENSOR_TYPE,
  DATABASE_TABLE
} from "../constants";
import showSecondarySidebar from "../components/Layout/helpers/showSecondarySidebar";
import { buildAbility } from "../services/auth/accessRight";
import { FEATURE } from "../constants";
import { PrimaryButton } from "../components/Button";

const FormItem = Form.Item;

class EditEmailForm extends React.Component {
  state = {
    deviceId: "",
    vehiclePlate: "",
    userFirebaseUserId: "",
    vendorFirebaseUserId: "",
    simCardNumber: "",
    expiryDate: "",
    deviceType: "",
    sensorType: "",
    oldFirebaseUserId: "",

    userList: [],
    vendorDisplayName: ""
  };

  submitForm = () => {
    let isValid = true;

    if (!validateImei(this.state.deviceId)) {
      message.error("Invalid Device ID");
      isValid = false;
    }

    if (this.state.vehiclePlate === "") {
      message.error("Invalid Vehicle Plate");
      isValid = false;
    }

    if (
      !this.state.userFirebaseUserId ||
      this.state.userFirebaseUserId === ""
    ) {
      message.error("Invalid Assigned User");
      isValid = false;
    }

    if (!validatePhoneInput(this.state.simCardNumber)) {
      message.error("Invalid SIM card number");
      isValid = false;
    }

    if (!this.state.expiryDate || this.state.expiryDate === "") {
      message.error("Invalid Expiry Date");
      isValid = false;
    }

    if (!this.state.deviceType || this.state.deviceType === "") {
      message.error("Invalid Device Type");
      isValid = false;
    }

    if (!this.state.sensorType || this.state.sensorType === "") {
      message.error("Invalid Sensor Type");
      isValid = false;
    }

    if (isValid) {
      this.props.onConfirm({
        deviceId: this.state.deviceId,
        plateNumber: this.state.vehiclePlate,
        firebaseUserId: this.state.userFirebaseUserId,
        vendorFirebaseUserId: retrieveUserData().uid,
        simCardNumber: this.state.simCardNumber,
        expiryDate: this.state.expiryDate,
        deviceType: this.state.deviceType,
        sensorType: this.state.sensorType,
        oldFirebaseUserId: this.state.oldFirebaseUserId
      });
    }
  };

  componentDidMount() {
    this.props.dispatch(
      dispatchWithUserData(() => {
        let { user } = this.props.user;
        if (!user) user = {};

        if (
          !buildAbility(user.accountType).can(
            "manage",
            FEATURE.DISTRIBUTOR_DASHBOARD
          )
        ) {
          this.props.dispatch(moveToPage("/"));
          return;
        }

        this.setState({
          vendorFirebaseUserId: user.firebaseUserId,
          vendorDisplayName: user.displayName
        });
        this.props.dispatch(
          requestData(
            API_RESOURCES.QUERY_HIERARCHY,
            { hierarchy: user.hierarchy },
            "userHierarchy",
            () => {
              let { userHierarchy } = this.props.request.data;
              if (!userHierarchy.result) userHierarchy.result = [];

              userHierarchy = userHierarchy.result;
              this.setState({ userList: userHierarchy });

              const { state } = this.props.router.location;
              if (!state.deviceId)
                message.error(
                  "Unable to get device info, please cancel and try again."
                );
              else {
                const requestKey = `device${state.deviceId}`;
                this.props.dispatch(
                  requestData(
                    API_RESOURCES.GET_DATA,
                    {
                      tableName: DATABASE_TABLE.DEVICE,
                      id: state.deviceId,
                      data: []
                    },
                    requestKey,
                    () => {
                      let { result } = this.props.request.data[requestKey];
                      result = result[0];
                      console.log("result :", result);
                      this.setState({
                        oldFirebaseUserId: result.firebaseUserId,
                        deviceId: result.deviceId,
                        vehiclePlate: result.plateNumber,
                        userFirebaseUserId: result.firebaseUserId,
                        vendorFirebaseUserId: result.vendorFirebaseUserId,
                        simCardNumber: result.simCardNumber,
                        expiryDate: result.expiryDate,
                        deviceType: result.deviceType,
                        sensorType: result.sensorType
                      });
                    }
                  )
                );
              }
            }
          )
        );
      })
    );
  }

  render() {
    return (
      <Form className="login-form">
        <FormItem
          label="Device ID"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Input
            disabled
            placeholder="860124123578906"
            value={this.state.deviceId}
            onChange={e => this.setState({ deviceId: e.target.value })}
          />
        </FormItem>
        <FormItem
          label="Vehicle Plate"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Input
            placeholder="WWW 1288"
            value={this.state.vehiclePlate}
            onChange={e => this.setState({ vehiclePlate: e.target.value })}
          />
        </FormItem>
        <FormItem
          label="User:"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Select
            placeholder="Select a user device is assigned to"
            value={this.state.userFirebaseUserId}
            onChange={value => this.setState({ userFirebaseUserId: value })}
          >
            {this.state.userList.map(user => {
              return (
                <Select.Option
                  key={user.firebaseUserId}
                  value={user.firebaseUserId}
                >
                  {user.displayName}
                </Select.Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem
          label="Servicer"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Input
            disabled
            placeholder="GPS Fleet"
            value={this.state.vendorDisplayName}
          />
        </FormItem>
        <FormItem
          label="SIM Contract"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Input
            placeholder="+60182231122"
            value={this.state.simCardNumber}
            onChange={e => this.setState({ simCardNumber: e.target.value })}
          />
        </FormItem>
        <FormItem
          label="Expiry Date"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            onChange={dateObject => {
              this.setState({
                expiryDate: new Date(dateObject.toDate()).getTime()
              });
            }}
          />
        </FormItem>
        <FormItem
          label="Device Type:"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Select
            placeholder="Select a device type"
            value={this.state.deviceType}
            onChange={value => this.setState({ deviceType: value })}
          >
            {DEVICE_TYPE.map(deviceType => {
              return (
                <Select.Option key={deviceType.value} value={deviceType.value}>
                  {deviceType.label}
                </Select.Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem
          label="Sensor:"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
        >
          <Select
            placeholder="Select a sensor type"
            onChange={value => this.setState({ sensorType: value })}
            value={this.state.sensorType}
          >
            {SENSOR_TYPE.map(sensorType => {
              return (
                <Select.Option key={sensorType.value} value={sensorType.value}>
                  {sensorType.label}
                </Select.Option>
              );
            })}
          </Select>
        </FormItem>

        <div style={{ position: "absolute", bottom: 20, right: 25 }}>
          <Button
            type="danger"
            className="login-form-button"
            onClick={this.props.onCancel}
          >
            Cancel
          </Button>

          <PrimaryButton onClick={this.submitForm}>Save</PrimaryButton>
        </div>
      </Form>
    );
  }
}

class DistributorEditPage extends React.Component {
  submitForm = data => {
    //TODO: Use transfer owner API for changing vehicle owner
    const loadingIndicator = message.loading("Updating Device...");

    const objectKeys = Object.keys(data);

    let updateList = objectKeys.map(key => {
      if (key === "deviceId" || key === "oldFirebaseUserId") return;
      else if (key === "firebaseUserId") {
        if (data.firebaseUserId !== data.oldFirebaseUserId) {
          this.props.dispatch(
            requestData(
              API_RESOURCES.TRANSFER_DEVICE,
              {
                deviceId: data.deviceId,
                newFirebaseUserId: data.firebaseUserId,
                oldFirebaseUserId: data.oldFirebaseUserId
              },
              "transferRequest",
              result => {
                console.log("Transfer request: ", result);
              }
            )
          );
        }
      } else {
        const updateObject = {
          deviceId: data.deviceId,
          updateRequest: {
            updateMethod: "SET",
            relatedAttributes: key,
            relatedData: data[key]
          }
        };

        return updateObject;
        // console.log('updateObject :', updateObject);
      }
    });

    updateList = updateList.filter(updateData => updateData);

    console.log("updateList :", updateList);

    this.props.dispatch(
      createDataList(
        API_RESOURCES.UPDATE_DEVICE_INFO,
        updateList,
        "updateDevice",
        updateResult => {
          // console.log("updateResult :", updateResult);
          message.success("Device updated");
          loadingIndicator();
          this.navigateBack();
        }
      )
    );
  };

  navigateBack = () => {
    this.props.dispatch(goBackToPrev());
  };

  render() {
    return (
      <div className="page-container">
        <div
          className="page-header"
          style={{
            backgroundColor: this.props.style.pageHeaderColor,
            color: this.props.style.textColor
          }}
        >
          <div className="page-header-contents">
            <button className="transparent-button" onClick={this.navigateBack}>
              <Icon type="left" />
            </button>
            <div>Distributor Dashboard</div>
          </div>
        </div>

        <div
          className="common-header"
          style={{
            backgroundColor: this.props.style.tableHeaderColor,
            color: this.props.style.textColor
          }}
        >
          Edit Device Details
        </div>

        <div style={{ padding: "30px 20px 0px 20px" }}>
          <EditEmailForm
            {...this.props}
            onCancel={this.navigateBack}
            onConfirm={this.submitForm}
          />
        </div>
      </div>
    );
  }
}

const connectedDistributorEditPage = connect(appStore => appStore)(
  DistributorEditPage
);

export default showSecondarySidebar(false)(connectedDistributorEditPage);
