import React from "react";
import PropTypes from "prop-types";
import PageTitle from "./PageTitle";
import "./index.css";

const Page = props => {
    return (
        <div className="page">
            <PageTitle title={props.title} />
            {props.children}
        </div>
    );
};

Page.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
};

export default Page;
