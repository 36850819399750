import { HTTP_METHOD } from "../../constants";
import { retrieveIdToken } from "../auth/authService";
import { access } from "fs";

/**
 * Configures the HTTP client with required fields and authentications
 * @param {string} httpMethod HTTP Method to send
 * @param {object} requestBody Request body of the request, if any
 */
export const setupClient = async (httpMethod, requestBody = null) => {
  if (
    (httpMethod === HTTP_METHOD.POST || httpMethod === HTTP_METHOD.PUT) &&
    !requestBody
  )
    throw new Error("Invalid request body for POST/PUT method");

  let accessToken = await retrieveIdToken();

  return {
    header: {
      tokenSource: accessToken
    },
    method: httpMethod,
    body: JSON.stringify(requestBody)
  };
};
