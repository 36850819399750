import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { createStore, applyMiddleware, compose } from "redux";
import { appStore } from "./services/redux/reducers/index";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import {
    connectRouter,
    routerMiddleware,
    ConnectedRouter
} from "connected-react-router";
import { Switch, Route, Redirect } from "react-router";
import localForage from "localforage";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { composeWithDevTools } from "redux-devtools-extension";
import firebase from "firebase/app";
import moment from 'moment-timezone';
import "firebase/messaging";
import config from "./config";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import "firebase/storage";

//Initialize Firebase Cloud Messaging (FCM)
firebase.initializeApp(config.firebaseConfig);
moment.tz.setDefault("Asia/Singapore");
const storage = firebase.storage();

// if (!isIOS) {
//     const fcm = firebase.messaging();

//     //Write Background push notification for FCM if Service Worker is available
//     if ("serviceWorker" in navigator) {
//         navigator.serviceWorker
//             .register("../firebase-messaging-sw.js")
//             .then(function(registration) {
//                 console.log(
//                     "Registration successful, scope is:",
//                     registration.scope
//                 );
//             })
//             .catch(function(err) {
//                 console.log("Service worker registration failed, error:", err);
//             });
//     }

//     fcm.onMessage(notification => {
//         console.log("Received Notification: ", notification);

//         store.dispatch(
//             triggerBrowserNotification(
//                 notification.notification.title,
//                 notification.notification.body
//             )
//         );
//     });

//     fcm.onTokenRefresh(() => {
//         //TODO: Send update token API
//     });
// }

const persistConfig = {
    key: "root",
    storage: localForage,
    whitelist: []
};

const persistedReducer = persistReducer(persistConfig, appStore);

const history = createBrowserHistory();
const rootReducerWithRouter = connectRouter(history)(persistedReducer);

export let store = createStore(
    rootReducerWithRouter,
    {},
    composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
);

let persistor = persistStore(store);

const ConnectedApp = ({ rootStore }) => {
    return (
        <Provider store={rootStore}>
            <PersistGate persistor={persistor}>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route exact path="/Login" component={LoginPage} />
                        <Route exact path="/SignUp" component={SignUpPage} />
                        <Route
                            path="/"
                            render={() => {
                                return <App />
                            }
                                // firebase.auth().currentUser ? (
                                //     <App />
                                // ) : (
                                //     <Redirect to={{ pathname: "/Login" }} />
                                // )
                            }
                        />
                    </Switch>
                </ConnectedRouter>
            </PersistGate>
        </Provider>
    );
};

registerServiceWorker();

firebase.auth().onAuthStateChanged(user => {
    ReactDOM.render(
        <ConnectedApp rootStore={store} />,
        document.getElementById("root")
    );
});

// storage.ref('tmp').child('logo').getDownloadURL().then(url => {
//     store.dispatch(setLogo(url));
// });

export { storage };
