export const EXPAND_SIDEBAR = "EXPAND_SIDEBAR";
export const ORIGINAL_SIDEBAR = "ORIGINAL_SIDEBAR";
/** most likely EXPAND_SIDEBAR and ORIGINAL_SIDEBAR will be deprecated in the future
 * in favor of TOGGLE_SIDEBAR, CLOSE_SIDEBAR and OPEN_SIDEBAR
 */
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const CHANGE_PAGE_HEADER_COLOR = "CHANGE_PAGE_HEADER_COLOR";
export const SET_LOGO = "SET_LOGO";
